import * as React from 'react';
import {useEffect} from 'react';
import Paper from '@mui/material/Paper';
import {default as RootBottomNavigation} from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import {Link, useLocation} from "react-router-dom";
import {BottomNavigationIcon} from "./BottomNavigationIcon";
import bottomNavigationItems from "./BottomNavigationItems";

export default function BottomNavigation() {
  const [value, setValue] = React.useState(0);
  let location = useLocation();

  useEffect(() => {
    if (location && location.pathname.startsWith("/terms")) {
      setValue(6);
    } else if (location && location.pathname.startsWith("/about")) {
      setValue(5);
    } else if (location && location.pathname.startsWith("/articles")) {
      setValue(2);
    } else if (location && location.pathname.startsWith("/news")) {
      setValue(2);
    } else if (location && location.pathname.startsWith("/blog")) {
      setValue(2);
    } else if (location && location.pathname.startsWith("/ideas")) {
      setValue(1);
    } else if (location && location.pathname.startsWith("/calculator")) {
      setValue(0);
    } else if (location && location.pathname.startsWith("/exchanges")) {
      setValue(3);
    } else {
      setValue(0);
    }
  }, [location]);

  return (
    <Paper className={'bottom-nav-wrapper'} sx={{position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: '1100!important'}} elevation={3}>
      <RootBottomNavigation
        sx={{
          height: '50px',
          '& .MuiButtonBase-root.MuiBottomNavigationAction-root': {
            textDecoration: 'none',
            color: (theme) => theme.palette.text.secondary
          },
          '& .MuiBottomNavigationAction-label.Mui-selected': {
            fontWeight: 600,
          },
          '& .MuiButtonBase-root.MuiBottomNavigationAction-root.Mui-selected': {
            color: (theme) => theme.palette.primary.dark
          }
        }}
        showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
      >
        {bottomNavigationItems.map((bottomNavigationItem) => (
          <BottomNavigationAction
            key={bottomNavigationItem.name}
            component={Link}
            to={bottomNavigationItem.link}
            disableRipple
            label={bottomNavigationItem.label}
            icon={<BottomNavigationIcon icon={bottomNavigationItem.icon} active={value === bottomNavigationItem.value}/>}
            sx={{
              '& .MuiBottomNavigationAction-label': {
                color: value === bottomNavigationItem.value ? 'primary.dark' : 'default'
              }
            }}
          />
        ))}
      </RootBottomNavigation>
    </Paper>
  );
}
