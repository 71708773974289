import {Button, ButtonProps, Stack, styled} from "@mui/material";
import * as React from "react";

const StyledButton = styled(Button)<ButtonProps>(({theme}) => ({
  borderRadius: '32px',
  color: theme.palette.text.primary,
  alignItems: 'end',
  padding: '4px 5px!important',
  '&.MuiButton-text': {
    color: theme.palette.text.secondary,
  },
  '&.MuiButton-contained': {
    backgroundColor: '#FFF0CA',
    borderColor: theme.palette.primary.main,
    borderWidth: '1px',
    borderStyle: 'solid',
    color: 'black',
  }
}));

export interface TimeSeriesChartButtonsProps{
  duration: number;
  onChange: (duration: number) => unknown;
}

export default function TimeSeriesChartButtons(props: TimeSeriesChartButtonsProps){
  const {
    duration,
    onChange
  } = props;

  return (
    <Stack
      className={'single-currency-chart-buttons'}
      sx={{
        bgcolor: 'transparent',
        borderRadius: '20px',
        py: 1
      }}
      direction={'row'}
      justifyContent={'space-around'}>
      <StyledButton
        variant={duration === 1 ? 'contained' : 'text'}
        size={'small'}
        color={duration === 1 ? 'secondary' : undefined}
        onClick={() => onChange(1)}
      >روزانه</StyledButton>
      <StyledButton
        variant={duration === 7 ? 'contained' : 'text'}
        size={'small'}
        color={duration === 7 ? 'secondary' : undefined}
        onClick={() => onChange(7)}
      >هفتگی</StyledButton>
      <StyledButton
        variant={duration === 30 ? 'contained' : 'text'}
        size={'small'}
        color={duration === 30 ? 'secondary' : undefined}
        onClick={() => onChange(30)}
      >ماهیانه</StyledButton>
      <StyledButton
        variant={duration === 360 ? 'contained' : 'text'}
        size={'small'}
        color={duration === 360 ? 'secondary' : undefined}
        onClick={() => onChange(360)}
      >سالیانه</StyledButton>
    </Stack>
  );
}