import * as React from 'react';
import {useEffect, useState} from 'react';
import {
  Backdrop,
  Box,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  List,
  ListItem, ListItemProps,
  ListItemText, ListItemTextProps,
  Paper, styled, Typography
} from '@mui/material';
import CryptoToCryptoCalculator from "./CryptoToCryptoCalculator";
import CryptoToFiatCalculator from "./CryptoToFiatCalculator";
import LabeledSwitch from "../LabeledSwitch/LabeledSwitch";
import {ItemInterface} from "./types";
import {number_format} from "../../tools/helpers";

const TOMAN_TEXT = 'تومان';

const CustomListItem = styled(ListItem)<ListItemProps>(({theme}) => ({
  direction: 'rtl',
  textAlign: 'right'
}))
const CustomListItemText = styled(ListItemText)<ListItemTextProps>(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  flexDirection: 'row',
  justifyContent: 'space-between',
  '& .MuiListItemText-primary': {
    color: theme.palette.text.secondary,
    fontSize: '12px'
  },
  '& .MuiListItemText-secondary': {
    color: theme.palette.text.primary,
    fontSize: '12px',
    fontWeight: '500',
    direction: 'rtl',
    textAlign: 'left'
  }
}))
export default function Calculator() {
  const [tab, setTab] = useState<0 | 1>(0);
  const [cryptoLoading, setCryptoLoading] = useState(false);
  const [fiatLoading, setFiatLoading] = useState(false);
  const [dollarValue, setDollarValue] = useState<number>(0)
  const [irtValue, setIrtValue] = useState<number>(0);
  const [usdtRate, setUsdtRate] = useState<number>(0);
  const [ctcSourceValue, setCtcSourceValue] = useState<number>(0);
  const [ctcSourceItem, setCtcSourceItem] = useState<ItemInterface | null>(null);
  const [cttSourceValue, setCttSourceValue] = useState<number>(0);
  const [cttTargetValue, setCttTargetValue] = useState<number>(0);
  const [cttSourceItem, setCttSourceItem] = useState<ItemInterface | null>(null);
  const [cttTargetItem, setCttTargetItem] = useState<ItemInterface | null>(null);
  const [usdtItem, setUsdtItem] = useState<ItemInterface | null>(null)

  useEffect(() => {
    setUsdtRate(usdtItem?.price_toman ?? 0);
  }, [usdtItem]);
  useEffect(() => {
    if(tab === 1){
      setDollarValue(ctcSourceValue * (ctcSourceItem?.price_usd ? ctcSourceItem?.price_usd : 0));
    }else{
      setDollarValue(cttSourceValue * (cttSourceItem?.price_usd ? cttSourceItem?.price_usd : 0));
    }
  }, [ctcSourceItem, ctcSourceValue, tab, cttSourceValue, cttSourceItem])
  useEffect(() => {
    if(tab === 1){
      setIrtValue(ctcSourceValue * (ctcSourceItem?.price_toman ? ctcSourceItem?.price_toman : 0))
    }else{
      if(cttTargetItem?.symbol === 'IRT'){
        setIrtValue(cttTargetValue)
      }else{
        setIrtValue(cttSourceValue * (cttSourceItem?.price_toman ? cttSourceItem?.price_toman : 0))
      }
    }
  }, [ctcSourceItem, ctcSourceValue, tab, cttSourceValue, cttSourceItem, cttTargetItem, cttTargetValue])

  const handleCryptoLoading = (loading: boolean) => {
    setCryptoLoading(loading)
  }
  const handleFiatLoading = (loading: boolean) => {
    setFiatLoading(loading)
  }

  const handleCtcSourceValueChange = (newSourceValue: number) => {
    setCtcSourceValue(newSourceValue);
  }
  const handleCtcSourceItemChange = (newSourceItem: ItemInterface) => {
    setCtcSourceItem(newSourceItem);
  }

  const handleCttSourceValueChange = (newSourceValue: number) => {
    setCttSourceValue(newSourceValue);
  }
  const handleCttTargetValueChange = (newTargetValue: number) => {
    setCttTargetValue(newTargetValue);
  }
  const handleCttSourceItemChange = (newSourceItem: ItemInterface) => {
    setCttSourceItem(newSourceItem);
  }
  const handleCttTargetItemChange = (newTargetItem: ItemInterface) => {
    setCttTargetItem(newTargetItem);
  }

  const handleUsdtItemChange = (newUsdtItem: ItemInterface) => {
    setUsdtItem(newUsdtItem);
  }
  return (
    <Paper
      elevation={0}
      sx={{
        borderRadius: 0,
        px: 1,
        mt: 3
      }}
    >
      <Box
        sx={{
          display: 'flex',
          pb: 2,
          justifyContent: 'center'
        }}
      >
        <LabeledSwitch
          label1={'کوین به ارز رایج'}
          label2={'کوین به کوین'}
          checked={!Boolean(tab)}
          onChange={(event) => setTab(event.target.checked ? 0 : 1)}
        />
      </Box>
      <Card
        id={'ran-calculator-card'}
        sx={{
          borderWidth: '2px',
          borderStyle: 'solid',
          borderColor: (theme) => theme.palette.divider,
          borderRadius: 4,
          boxShadow: '0',
          position: 'relative'
        }}>
        {
          <Backdrop
            sx={{
              color: '#fff',
              position: 'absolute',
              backdropFilter: 'blur(5px)',
              zIndex: (theme) => theme.zIndex.drawer + 1
            }}
            open={cryptoLoading || fiatLoading}
          >
            <CircularProgress color="inherit"/>
          </Backdrop>
        }
        <CardContent id={'ran-calculator-ctf'} sx={{display: tab === 0 ? 'block' : 'none'}}>
          <CryptoToFiatCalculator
            onLoad={handleFiatLoading}
            onUsdtItemChanged={handleUsdtItemChange}
            onSourceValueChanged={handleCttSourceValueChange}
            onSourceItemChanged={handleCttSourceItemChange}
            onTargetValueChanged={handleCttTargetValueChange}
            onTargetItemChanged={handleCttTargetItemChange}
          />
        </CardContent>
        <CardContent id={'ran-calculator-ctc'} sx={{display: tab === 1 ? 'block' : 'none', paddingBottom: '16px!important'}}>
          <CryptoToCryptoCalculator
            onLoad={handleCryptoLoading}
            onSourceValueChanged={handleCtcSourceValueChange}
            onSourceItemChanged={handleCtcSourceItemChange}
          />
        </CardContent>
      </Card>
      <Card
        sx={{
          borderWidth: '2px',
          borderStyle: 'solid',
          borderColor: (theme) => theme.palette.divider,
          borderRadius: 4,
          boxShadow: '0',
          position: 'relative',
          mt: 3
        }}>
        <CardContent sx={{paddingBottom: '16px!important'}}>
          <List sx={{width: '100%'}}>
            <CustomListItem key={'dollar-value'}>
              <CustomListItemText
                primary={'معادل دلاری'}
                secondary={`${number_format(dollarValue)} $`}
              />
            </CustomListItem>
            <Divider sx={{mx: 0}} variant="inset" component="li" />
            <CustomListItem key={'toman-value'}>
              <CustomListItemText
                sx={{
                  '& .MuiListItemText-secondary': {
                    fontFamily: 'IRANSansXFaNum!important',
                  }
                }}
                primary={'ارزش به تومان'}
                secondary={`${number_format(irtValue)} ${TOMAN_TEXT}`}
              />
            </CustomListItem>
            <Divider sx={{mx: 0}} variant="inset" component="li" />
            <CustomListItem key={'tether-value'}>
              <CustomListItemText
                sx={{
                  '& .MuiListItemText-secondary': {
                    fontFamily: 'IRANSansXFaNum!important',
                  }
                }}
                primary={'نرخ تتر'}
                secondary={`${number_format(usdtRate)} ${TOMAN_TEXT}`}
              />
            </CustomListItem>
          </List>
        </CardContent>
      </Card>
    </Paper>
  );
}