import {createSvgIcon} from "@mui/material";

const SearchIcon = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" fill="inherit" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M11.5 1.25C5.83908 1.25 1.25 5.83908 1.25 11.5C1.25 17.1609 5.83908 21.75 11.5 21.75C17.1609 21.75 21.75 17.1609 21.75 11.5C21.75 5.83908 17.1609 1.25 11.5 1.25ZM2.75 11.5C2.75 6.66751 6.66751 2.75 11.5 2.75C16.3325 2.75 20.25 6.66751 20.25 11.5C20.25 16.3325 16.3325 20.25 11.5 20.25C6.66751 20.25 2.75 16.3325 2.75 11.5Z" fill="inherit"/>
    <path d="M20.5303 19.4697C20.2374 19.1768 19.7626 19.1768 19.4697 19.4697C19.1768 19.7626 19.1768 20.2374 19.4697 20.5303L21.4697 22.5303C21.7626 22.8232 22.2374 22.8232 22.5303 22.5303C22.8232 22.2374 22.8232 21.7626 22.5303 21.4697L20.5303 19.4697Z" fill="inherit"/>
  </svg>,
  'Search'
);

export default SearchIcon;
