import {Box} from "@mui/material";
import Post from "../../components/Post";
import {useParams} from "react-router-dom";

export default function SingleNews() {
  const {id} = useParams();
  return (
    <Box>
      <Post
        postId={id}
        api={`https://ramzarz.news/wp-json/wp/v2/posts/${id}?_fields=id,title,content,modified,image,thumbnail,author_name`}
      />
    </Box>
  );
}
