import {createContext, useReducer, ReactNode, useContext} from "react";
import {PaletteMode} from "@mui/material";

import {ThemeProvider as BaseThemeProvider} from '@mui/material';

import Themes from '../theme';

export const ThemeModeContext = createContext<PaletteMode>('light');
export const ThemeModeDispatchContext = createContext<any>(null);

export function useThemeMode(){
  return useContext(ThemeModeContext);
}
export function useThemeModeDispatch(){
  return useContext(ThemeModeDispatchContext);
}

export type ThemeModeReducerAction = 'toggle' | 'light' | 'dark';

export function ThemeProvider({ children }: {children: ReactNode}) {
  const [themeMode, dispatch] = useReducer(
    themeModeReducer,
    localStorage.getItem('theme') === 'dark' ? 'dark' : 'light'
  );
  return (
    <ThemeModeContext.Provider value={themeMode}>
      <ThemeModeDispatchContext.Provider value={dispatch}>
        <BaseThemeProvider theme={themeMode === 'dark' ? Themes.darkTheme : Themes.lightTheme}>
          {children}
        </BaseThemeProvider>
      </ThemeModeDispatchContext.Provider>
    </ThemeModeContext.Provider>
  );
}

function themeModeReducer(themeMode: PaletteMode, action: ThemeModeReducerAction) {
  switch (action){
    case 'toggle': {
      return themeMode === 'dark' ? 'light' : 'dark';
    }
    case 'light': {
      return 'light';
    }
    case 'dark': {
      return 'dark';
    }
    default: {
      return 'light';
    }
  }
}