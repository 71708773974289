import * as React from "react";
import {useEffect, useState} from "react";
import {Box} from "@mui/material";

const ZERO_TEXT_FA = 'صفر';

const calculateValue = (v: string): string => {
  return parseInt(v) > 1 ? new Intl.NumberFormat('en-US').format(parseInt(v)) : parseFloat(v).toFixed(6)
}

export default function Toman(props: { irt: string, usd: string, symbol?: string }) {
  const [usd, setUsd] = useState(props.usd)
  const [irt, setIrt] = useState(props.irt)
  const [value, setValue] = useState(() => calculateValue(props.irt))

  useEffect(() => {
    setIrt(props.irt);
  }, [props.irt])

  useEffect(() => {
    if (parseFloat(usd) === 0) {
      setIrt('0');
    } else {
      setIrt((parseFloat(props.usd) * (parseFloat(irt) / parseFloat(usd))).toString());
    }
    setUsd(props.usd)
  }, [props.usd])

  useEffect(() => {
    setValue(calculateValue(irt))
  }, [irt])

  return <Box
    component={'span'}
    sx={{
      color: (theme) => theme.palette.text.secondary
    }}
    className='iransans-fa-num'>{parseFloat(irt) == 0 ? ZERO_TEXT_FA : `${value} ${props.symbol}`}</Box>;
}
