import {Box, Container, Grid, IconButton, Paper, Typography} from "@mui/material";
import EastIcon from "@mui/icons-material/East";
import * as React from "react";
import {Link} from "react-router-dom";

export default function Terms() {
  return (
    <Paper elevation={0} sx={{p: 0, m: 0, pb: 2}}>
      <Container maxWidth={false}>
        <Grid container>
          <Grid item xs={12}
                sx={{
                  textAlign: 'center',
                  mb: 2,
                  mt: 1,
                  direction: 'ltr',
                  display: 'flex',
                }}
          >
            <Box
              sx={{
                fontSize: '1.8rem',
                flexGrow: 1,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '4px',
              }}
            >
              <Typography variant='h4'>
                قوانین و شرایط
              </Typography>
            </Box>
            <Box>
              <IconButton
                component={Link}
                to={'/profile'}
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                color="inherit"
              >
                <EastIcon fontSize={"large"}/>
              </IconButton>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography
              sx={{
                color: (theme) => theme.palette.primary.dark
              }}
              variant='h5' mt={3} mb={3}>
              قوانین و شرایط استفاده
            </Typography>
            <Typography variant='h6' mb={3}>
              همراهان گرامی رمز ارز نیوز لطفا موارد زیر را هنگام استفاده از مطالب اپلیکیشن در نظر بگیرید:
            </Typography>
            <Typography variant='body1'>
              <ul>
                <li>مقالات و اخبار موجود در اپلیکیشن رمزارزنیوز، با استفاده از منابع معتبر، ارائه شده و صرفاً جنبه آموزش
                  و اطلاع رسانی دارد. هر گونه استفاده از این اطلاعات به منظور خرید و فروش یا سرمایه&zwnj;گذاری در هر یک
                  از رمزارزها با مسئولیت خود کاربران خواهد بود.
                </li>
                <li>استفاده و نشر مطالب اپلیکیشن، تنها با ذکر منبع آنها امکان&zwnj;پذیر است.</li>
                <li>رمزارزنیوز هیچ گونه فعالیتی در زمینه خرید و فروش رمزارز ندارد، بنابراین در صورتی که قصد خرید و فروش
                  هر یک از رمزارزهای معرفی شده در مقالات یا اخبار اپلیکیشن رمزارز نیوز را دارید، در انتخاب منابع معتبر
                  دقت داشته باشید.
                </li>
                <li>اپلیکیشن رمزارزنیوز هیچ گونه فعالیتی در زمینه خرید و فروش دستگاه&zwnj;های ماینر ندارد، در صورتی که
                  بعد از بررسی اطلاعات ارائه شده در اپلیکیشن، تصمیم به خرید دستگاه ماینر گرفتید، در انتخاب منابع یا
                  فروشگاه&zwnj;های معتبر دقت کنید.
                </li>
                <li>تمامی ابزارها، اطلاعات قیمتی و مطالب آموزشی موجود در اپلیکیشن به منظور کمک رسانی و افزایش آگاهی
                  کاربران صورت گرفته است و بررسی صحت هر یک از این اطلاعات و تصمیم گیری در مورد چگونگی استفاده از هریک از
                  آنها برعهده خود کاربران خواهد بود.
                </li>
                <li>رمزارز نیوز استفاده از فضاهای مربوط به تبلیغات در اپلیکیشن، وب سایت و شبکه&zwnj;های اجتماعی خود را
                  مجاز می&zwnj;داند.
                </li>
                <li>بررسی صحت عملکرد و کارآیی هر یک از خدماتی که از طریق تبلیغات در اپلیکیشن، وبسایت و یا فضاهای مجازی
                  مربوط به رمزارزنیوز ارائه شده&zwnj;اند برعهده کاربران است.
                </li>
                <li>رمزارز نیوز تابع قوانین جمهوری اسلامی ایران است؛ بدیهی است که در صورتی که هر گونه تغییراتی در قوانین
                  موجود رخ دهد، رمزارز نیوز از آنها تبعیت خواهد کرد.
                </li>
              </ul>
            </Typography>
            <Typography
              sx={{
                color: (theme) => theme.palette.primary.dark
              }}
                        variant='h5' my={3}>
              منابع مورد استفاده
            </Typography>
            <Typography variant='body1'>
              تمام اطلاعات مربوط به قیمت ارزهای دیجیتال، جدول قیمت‌ها و ... از معتبرترین صرافی‌ها و سایت‌ها مانند
              بایننس، کوین مارکت کپ، تریدینگ ویوو و … دریافت و پردازش می‌شود. رمزارز نیوز تلاش می‌کند دقیق ترین اطلاعات
              را با کم‌ترین خطای ممکن ارائه دهد و به طور دائم به روز رسانی‌های لازم را انجام دهد. با این حال، با توجه به
              دریافت اطلاعات از منابع خارجی، این مجموعه تعهدی بابت صحت همیشگی داده‌ها و ضرر و زیان‌های ناشی از معاملات
              بر اساس نرخ های اعلام شده ندارد.
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Paper>
  );
}
