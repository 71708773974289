import {SvgIconProps} from "./types";


const IdeasIcon = (props: SvgIconProps) => {
  const {
    fill,
    type,
    size
  } = props;

  return (
    <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} fill={fill} xmlns="http://www.w3.org/2000/svg">
      {type === 'filled' && (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2ZM7.63 18.15C7.63 18.56 7.29 18.9 6.88 18.9C6.47 18.9 6.13 18.56 6.13 18.15V16.08C6.13 15.67 6.47 15.33 6.88 15.33C7.29 15.33 7.63 15.67 7.63 16.08V18.15ZM12.75 18.15C12.75 18.56 12.41 18.9 12 18.9C11.59 18.9 11.25 18.56 11.25 18.15V14C11.25 13.59 11.59 13.25 12 13.25C12.41 13.25 12.75 13.59 12.75 14V18.15ZM17.87 18.15C17.87 18.56 17.53 18.9 17.12 18.9C16.71 18.9 16.37 18.56 16.37 18.15V11.93C16.37 11.52 16.71 11.18 17.12 11.18C17.53 11.18 17.87 11.52 17.87 11.93V18.15ZM17.87 8.77C17.87 9.18 17.53 9.52 17.12 9.52C16.71 9.52 16.37 9.18 16.37 8.77V7.8C13.82 10.42 10.63 12.27 7.06 13.16C7 13.18 6.94 13.18 6.88 13.18C6.54 13.18 6.24 12.95 6.15 12.61C6.05 12.21 6.29 11.8 6.7 11.7C10.07 10.86 13.07 9.09 15.45 6.59H14.2C13.79 6.59 13.45 6.25 13.45 5.84C13.45 5.43 13.79 5.09 14.2 5.09H17.13C17.17 5.09 17.2 5.11 17.24 5.11C17.29 5.12 17.34 5.12 17.39 5.14C17.44 5.16 17.48 5.19 17.53 5.22C17.56 5.24 17.59 5.25 17.62 5.27C17.63 5.28 17.63 5.29 17.64 5.29C17.68 5.33 17.71 5.37 17.74 5.41C17.77 5.45 17.8 5.48 17.81 5.52C17.83 5.56 17.83 5.6 17.84 5.65C17.85 5.7 17.87 5.75 17.87 5.81C17.87 5.82 17.88 5.83 17.88 5.84V8.77H17.87Z" fill={fill}/>
        </svg>
      )}
      {type === 'outlined' && (
        <svg width="24" height="24" viewBox="0 0 24 24" fill={fill} xmlns="http://www.w3.org/2000/svg">
          <path d="M6.69861 11.7023C10.053 10.8659 13.0921 9.09341 15.4611 6.59998H14.1904C13.7762 6.59998 13.4404 6.26419 13.4404 5.84998C13.4404 5.43576 13.7762 5.09998 14.1904 5.09998H17.1204C17.5346 5.09998 17.8704 5.43576 17.8704 5.84998V8.76998C17.8704 9.18419 17.5346 9.51998 17.1204 9.51998C16.7062 9.51998 16.3704 9.18419 16.3704 8.76998V7.8182C13.8313 10.4214 10.6087 12.2732 7.06151 13.1577C6.6596 13.2579 6.25255 13.0134 6.15234 12.6114C6.05213 12.2095 6.2967 11.8025 6.69861 11.7023Z" fill={fill}/>
          <path d="M17.1201 11.1801C17.5343 11.1801 17.8701 11.5158 17.8701 11.9301V18.1501C17.8701 18.5643 17.5343 18.9001 17.1201 18.9001C16.7059 18.9001 16.3701 18.5643 16.3701 18.1501V11.9301C16.3701 11.5158 16.7059 11.1801 17.1201 11.1801Z" fill={fill}/>
          <path d="M12.75 14.01C12.75 13.5958 12.4142 13.26 12 13.26C11.5858 13.26 11.25 13.5958 11.25 14.01V18.15C11.25 18.5642 11.5858 18.9 12 18.9C12.4142 18.9 12.75 18.5642 12.75 18.15V14.01Z" fill={fill}/>
          <path d="M7.62988 16.08C7.62988 15.6657 7.2941 15.33 6.87988 15.33C6.46567 15.33 6.12988 15.6657 6.12988 16.08V18.15C6.12988 18.5642 6.46567 18.9 6.87988 18.9C7.2941 18.9 7.62988 18.5642 7.62988 18.15V16.08Z" fill={fill}/>
          <path fillRule="evenodd" clipRule="evenodd" d="M9 1.25C6.42603 1.25 4.42914 1.7602 3.09467 3.09467C1.7602 4.42914 1.25 6.42603 1.25 9V15C1.25 17.574 1.7602 19.5709 3.09467 20.9053C4.42914 22.2398 6.42603 22.75 9 22.75H15C17.574 22.75 19.5709 22.2398 20.9053 20.9053C22.2398 19.5709 22.75 17.574 22.75 15V9C22.75 6.42603 22.2398 4.42914 20.9053 3.09467C19.5709 1.7602 17.574 1.25 15 1.25H9ZM2.75 9C2.75 6.57397 3.2398 5.07086 4.15533 4.15533C5.07086 3.2398 6.57397 2.75 9 2.75H15C17.426 2.75 18.9291 3.2398 19.8447 4.15533C20.7602 5.07086 21.25 6.57397 21.25 9V15C21.25 17.426 20.7602 18.9291 19.8447 19.8447C18.9291 20.7602 17.426 21.25 15 21.25H9C6.57397 21.25 5.07086 20.7602 4.15533 19.8447C3.2398 18.9291 2.75 17.426 2.75 15V9Z" fill={fill}/>
        </svg>
      )}
    </svg>
  );
};

export default IdeasIcon;