import {Divider, Grid, Paper, useMediaQuery} from "@mui/material";
import * as React from "react";
import SortableButton from "./SortableButton";

export default function CurrencyHead(props: {
  sort: 'cmcRank' | 'price' | 'percentChange24h',
  order: 'asc' | 'desc',
  onSortChange: (newVal: 'cmcRank' | 'price' | 'percentChange24h') => void,
  onOrderChange: (newVal: 'asc' | 'desc') => void,
}) {
  const {
    sort,
    order,
    onSortChange,
    onOrderChange
  } = props;
  const matches = useMediaQuery('(max-width:560px)');
  return (
    <Paper elevation={0} sx={{backgroundColor: 'primary.light', borderRadius: 0}}>
      <Grid container py={1}>
        <Grid item xs={1.5} sm={1} sx={{textAlign: 'center', my: 'auto'}}>
          <SortableButton
            name={'#'}
            value={'cmcRank'}
            sort={sort}
            order={order}
            onSortChange={onSortChange}
            onOrderChange={onOrderChange}
          />
        </Grid>
        <Grid item xs={3.5} sm={4} sx={{textAlign: 'left',pl: matches ? '16px' : '32px', my: 'auto', fontSize: '0.75rem'}}>ارز</Grid>
        <Grid item xs={4} sm={5} sx={{textAlign: 'center', my: 'auto'}}>
          <SortableButton
            name={'قیمت'}
            value={'price'}
            sort={sort}
            order={order}
            onSortChange={onSortChange}
            onOrderChange={onOrderChange}
          />
        </Grid>
        <Grid item xs={3} sm={2} sx={{textAlign: 'center', my: 'auto'}}>
          <SortableButton
            name={'تغییرات روزانه'}
            value={'percentChange24h'}
            sort={sort}
            order={order}
            onSortChange={onSortChange}
            onOrderChange={onOrderChange}
          />
        </Grid>
      </Grid>
      <Divider/>
    </Paper>
  );
}