import {Box, Button, Container, Grid, IconButton, InputAdornment, Paper, TextField} from "@mui/material";
import {useNavigate, useLocation} from "react-router-dom";
import './Search.css'
import {useEffect, useState} from "react";
import CurrencySearchResults from "../../components/CurrencySearchResults";
import SearchIcon from "../../components/Icons/SearchIcon";
import EastIcon from '@mui/icons-material/East';
import BlogSearchResults from "../../components/BlogSearchResults/BlogSearchResults";
import ArticlesSearchResults from "../../components/ArticlesSearchResults";
import IdeasSearchResults from "../../components/IdeasSearchResults";

type TabTypes = 'currencies' | 'ideas' | 'blog' | 'articles';

export default function Search() {
  const [query, setQuery] = useState('');
  const navigate = useNavigate();
  const [tab, setTab] = useState<TabTypes>('currencies');
  const {
    pathname
  } = useLocation();

  useEffect(() => {
    if(pathname.indexOf('ideas') !== -1){
      setTab('ideas');
    }else if(pathname.indexOf('blog') !== -1){
      setTab('blog');
    }else if(pathname.indexOf('articles') !== -1){
      setTab('articles')
    }else{
      setTab('currencies');
    }
  }, [pathname])

  function handleQuery(event: any) {
    setQuery(event.target.value);
  }

  const handleBackButtonClick = () => {
    if(tab === 'currencies'){
      navigate('/');
    }else if(tab === 'ideas'){
      navigate('/ideas');
    }else if(tab === 'blog'){
      navigate('/blog');
    }else if(tab === 'articles'){
      navigate('/articles');
    }else{
      navigate(-1);
    }
  }

  return (
    <Paper className={'search-paper'} elevation={0} sx={{p: 0, m: 0, pt: 1}}>
      <Container maxWidth={false}>
        <Grid item container>
          <Grid item xs={2} sm={1}>
            <IconButton
              onClick={handleBackButtonClick}
              aria-label="back"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              color="inherit"
            >
              <EastIcon fontSize={'large'}/>
            </IconButton>
          </Grid>
          <Grid item xs={10} sm={11} className={'search-input-wrapper'}>
            <TextField
              placeholder={'جستجو'}
              sx={{borderRadius: '30px'}}
              fullWidth
              id="search-input"
              variant="filled"
              size="small"
              value={query}
              onChange={handleQuery}
              InputProps={{
                endAdornment: <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                  >
                    <SearchIcon/>
                  </IconButton>
                </InputAdornment>
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              textAlign: 'center'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-around',
                maxWidth: '360px',
                mt: '8px'
              }}
            >
              <Button
                key={'currencies'}
                variant={tab === 'currencies' ? 'outlined' : 'text'}
                onClick={() => navigate('/search')}
              >ارزها</Button>
              <Button
                key={'ideas'}
                variant={tab === 'ideas' ? 'outlined' : 'text'}
                onClick={() => navigate('/search/ideas')}
              >تحلیل‌ها</Button>
              <Button
                key={'blog'}
                onClick={() => navigate('/search/blog')}
                variant={tab === 'blog' ? 'outlined' : 'text'}
              >اخبار</Button>
              <Button
                key={'articles'}
                onClick={() => navigate('/search/articles')}
                variant={tab === 'articles' ? 'outlined' : 'text'}
              >مقالات</Button>
            </Box>
          </Grid>
        </Grid>
        <Grid item container>
          <Grid item xs={12} hidden={tab !== 'currencies'}>
            <CurrencySearchResults query={query}/>
          </Grid>
          <Grid item xs={12} hidden={tab !== 'blog'}>
            <BlogSearchResults query={query}/>
          </Grid>
          <Grid item xs={12} hidden={tab !== 'articles'}>
            <ArticlesSearchResults query={query}/>
          </Grid>
          <Grid item xs={12} hidden={tab !== 'ideas'}>
            <IdeasSearchResults query={query}/>
          </Grid>
        </Grid>
      </Container>
    </Paper>
  );
}
