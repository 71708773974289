import * as React from 'react';
import {useCallback, useEffect, useState} from 'react';
import {Box, Container, Divider, Grid, Paper, Stack, Typography} from "@mui/material";
import axios from "axios";
import Loading from "../Loading";
import PersianDate from "../PersianDate/PersianDate";
import InfiniteScroll from "react-infinite-scroll-component";

interface IdeaInterface {
  id: string;
  title: string,
  thumbnail: string
  published_at: string;
  singlePath: string;
  slug: string;
  link: string;
  avatar: string;
}

interface IdeasListInterface {
  url: string;
  title: string;
  singlePath: string,
  icon: any,
}

function Header(props: { title: string, icon: any }) {
  return (
    <Grid container className={'posts-title'}>
      <Grid item>
        <Typography sx={{my: 1}}>
          {props.icon}
          <Box component='span'
               sx={{verticalAlign: 'top'}}>{props.title}</Box>
        </Typography>
      </Grid>
    </Grid>
  );
}


function PostRow(props: { post: IdeaInterface, singlePath: string }) {
  return (
    <Paper className={'post-paper'} elevation={0}>
      <Grid container sx={{py: 1}}>
        <Grid item xs={3} className={'post-media'}>
          <a href={props.post.link}>
            <Box component='img'
                 className={'post-img'}
                 src={'https://ramzarz.news/' + props.post.thumbnail}
                 sx={{
                   width: '70px',
                   height: '70px',
                   borderRadius: '3px'
                 }}
            />
          </a>
        </Grid>
        <Grid item xs={9} className={'post-title'}>
          <Stack>
            <Box className={'post-title'}>
              <a className='no-underline' href={props.post.link}>
                <Typography variant='h6' fontWeight='700' color='text.primary'>{props.post.title}</Typography>
              </a>
            </Box>
            <Box className={'post-date'}>
              <Typography fontSize={'0.5rem'} color='text.secondary'><PersianDate date={props.post.published_at}/></Typography>
            </Box>
          </Stack>
        </Grid>
      </Grid>
      <Divider/>
    </Paper>
  );
}

function Posts(props: { posts: IdeaInterface[], singlePath: string }) {
  return (
    <Box>
      {props.posts.map((post, index) => (
        <PostRow key={index} post={post} singlePath={props.singlePath}/>
      ))}
    </Box>
  );
}


export default function PostsList(props: IdeasListInterface) {
  const [posts, setPosts] = useState<IdeaInterface[]>([])
  const [finished, setFinished] = useState<boolean>(false)
  useEffect(() => {
    getPosts();
  }, []);

  const getPosts = useCallback(() => {
    const page = Math.floor(posts.length / 20) + 1;
    axios.get(`${props.url}&page=${page}`)
      .then(response => response.data)
      .then((data) => {
        setPosts(posts.concat(data))
      }).catch(function (err) {
      setFinished(true);
      if (err.code === "ERR_BAD_REQUEST" && err.response.status === 400 && err.response.data.code === "rest_post_invalid_page_number") {
        setFinished(true);
      }
    }).finally(() => {
    });
  }, [posts])
  return (
    <Container disableGutters maxWidth={false} sx={{px: 1}}>
      <Header title={props.title} icon={props.icon}/>
      <Divider/>
      <InfiniteScroll
        dataLength={posts.length}
        next={getPosts}
        hasMore={!finished}
        loader={<Loading/>}
        endMessage={
          <p style={{textAlign: 'center'}}>
            <b>اطلاعات بیشتر برای نمایش وجود ندارد</b>
          </p>
        }
        style={{overflow: 'unset'}}
      >
        <Posts posts={posts} singlePath={props.singlePath}/>
      </InfiniteScroll>

    </Container>
  );
}
