import {
  Avatar,
  Box,
  Button,
  ButtonProps,
  Divider, Drawer,
  IconButton,
  InputAdornment,
  LinearProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListSubheader,
  Skeleton,
  SwipeableDrawer,
  Typography
} from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {styled} from '@mui/material/styles';
import * as React from "react";
import {useEffect, useId, useRef, useState} from "react";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import api_url from "../../tools/helpers";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import Puller from "../Puller";

const CustomButton = styled(Button)<ButtonProps>(({theme}) => ({
  boxShadow: 'none',
  textTransform: 'none',
  fontSize: 14,
  padding: '1px 1px',
  minWidth: '150px',
  lineHeight: 2.5,
  color: theme.palette.text.primary,
  backgroundColor: theme.palette.primary.light,
  border: 'none!important',
  borderRadius: '24px',
  '&:hover': {
    boxShadow: 'none',
  },
  '&:active': {
    boxShadow: 'none',
    backgroundColor: 'inherit',
  },
  '&:focus': {
    boxShadow: 'none',
  },
  '& .MuiButton-startIcon': {
    marginRight: '2px',
  },
  '& .MuiBox-root': {
    paddingRight: '8px'
  }
}));

const ExchangeSelectorSkeleton = () => {
  return (
    <Box sx={{
      display: 'flex',
      justifyContent: 'center'
    }}>
      <Skeleton animation="wave" width={150} height={60} sx={{
        borderRadius: '24px'
      }} />
    </Box>
  );
}

interface ItemInterface {
  id: number;
  name_fa: string;
  name_en: string;
  abbr: string;
  icon: string;
}

function transformData(data: any[]): ItemInterface[] {
  return data.map((d: any) => ({
    id: d.id,
    name_fa: d.name_fa,
    name_en: d.name_en,
    abbr: d.abbr,
    icon: d.icon,
  }))
}

export default function ExchangesSelector(props: { currency?: string | undefined, onLoading?: (loading: boolean) => unknown }) {
  const {
    currency,
    onLoading
  } = props;

  const [items, setItems] = useState<ItemInterface[]>([]);
  const [filteredItems, setFilteredItems] = useState<ItemInterface[]>([]);
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState('')
  const [selectedItem, setSelectedItem] = useState<ItemInterface | null>(null);
  const containerId = useId();
  const elementRef = useRef<any>(null);
  const inputRef = useRef<any>(null);
  const [open, setOpen] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    let url = api_url(`/v1/currencies`);
    setLoading(true);
    axios.get(url).then(response => response.data)
      .then((data) => {
        setItems(transformData(data.data))
      })
      .finally(() => setLoading(false))
  }, []);

  useEffect(() => {
    if(!!onLoading){
      onLoading(loading || !selectedItem);
    }
  }, [loading, onLoading, selectedItem])

  useEffect(() => {
    if(currency !== undefined){
      items.forEach((item, indx) => {
        if (item.name_en.toLowerCase() === currency.toLowerCase()) {
          setSelectedItem(item);
        }
      })
    }
  }, [items, currency])

  useEffect(() => {
    let filteredItems = items;
    if (query) {
      filteredItems = items.filter((item) => {
        let found = false;
        if (item.name_en.toLowerCase().search(query.toLowerCase()) !== -1) {
          found = true;
        }
        if (item.name_fa.search(query) !== -1) {
          found = true;
        }
        if (item.abbr.toLowerCase().search(query.toLowerCase()) !== -1) {
          found = true;
        }
        return found;
      })
    }
    setFilteredItems(filteredItems);
  }, [query, items]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    document.documentElement.style.setProperty('overflow-y', 'hidden', 'important');
    setOpen(true);
  };
  const handleClose = () => {
    document.documentElement.style.setProperty('overflow-y', 'auto', 'important');
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  }
  return (
    <Box className={'exchanges-selector'} sx={{my: 2}} id={containerId} ref={elementRef}>
      <Box sx={{textAlign: 'center', px: 2}}>
        {loading ? (
          <ExchangeSelectorSkeleton />
        ) : (
          <CustomButton
            sx={{
              '& .MuiButton-endIcon': {
                mx: 0
              },
            }}
            variant={'outlined'}
            onClick={handleClick}
            fullWidth
            size={'large'}
            endIcon={<KeyboardArrowDownIcon/>}
            startIcon={(selectedItem && selectedItem.icon ? (
                <Avatar sx={{width: 24, height: 24}} src={selectedItem.icon} />
              ) : (
                <Skeleton animation="wave" variant="circular" width={24} height={24} />
              )
            )}
          >
            <Box sx={{flexGrow: 1, textAlign: 'start'}}>
              {selectedItem && selectedItem.name_fa ? selectedItem.name_fa : ''}
            </Box>
          </CustomButton>
        )}
      </Box>
      <Drawer
        anchor={'bottom'}
        open={open}
        onClose={handleClose}
        sx={{
          zIndex: '12000!important',
          '& .MuiPaper-root.MuiDrawer-paper': {
            borderTopRightRadius: '20px',
            borderTopLeftRadius: '20px',
          }
        }}
      >
        <List
          sx={{
            width: '100%',
            bgcolor: 'background.paper',
            position: 'relative',
            maxHeight: 450,
            overflowY: 'hidden',
            height: 300,
            '& ul': {padding: 0},
          }}
          subheader={<li/>}
        >
          <ul>
            <li>
              <ListSubheader
                disableGutters
              >
                <Puller />
                <TextField
                  inputRef={inputRef}
                  fullWidth
                  value={query}
                  onChange={(e) => setQuery(e.target.value)}
                  placeholder={'نام ارز موردنظر را وارد کنید'}
                  sx={{
                    '& fieldset': {
                      borderRadius: '24px',
                      mx: '8px',
                      my: '4px'
                    },
                    '& input': {
                      direction: 'ltr',
                      textAlign: 'left',
                      '&:-moz-placeholder': {
                        textAlign: 'right!important'
                      },
                      '&:-ms-input-placeholder': {
                        textAlign: 'right!important'
                      },
                      '&::-webkit-input-placeholder': {
                        textAlign: 'right!important'
                      }
                    }
                  }}
                  InputProps={{
                    startAdornment: <InputAdornment position="end"><IconButton
                      onClick={handleClose}><CloseIcon/></IconButton></InputAdornment>,
                    endAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
                  }}
                />
                <Divider/>
                {
                  loading && <LinearProgress color="inherit" sx={{height: '2px'}}/>
                }
              </ListSubheader>
              {filteredItems.map((item) => (
                <ListItem
                  selected={selectedItem !== null && item.id === selectedItem.id}
                  key={item.id}
                  onClick={() => {
                    navigate(`/exchanges/${item.name_en.toLowerCase()}`)
                    handleClose()
                  }}
                  disableGutters
                  sx={{
                    py: 0,
                    '&:hover': {
                      bgcolor: 'transparent'
                    }
                  }}
                >
                  <ListItemButton disableRipple disableTouchRipple>
                    <ListItemIcon>
                      <Avatar
                        sx={{width: '16px', height: '16px'}}
                        src={item.icon}
                      />
                      <Typography mx={1} color={'text.primary'} fontWeight={'500'}>{item.name_fa}</Typography>
                    </ListItemIcon>
                  </ListItemButton>
                </ListItem>
              ))}
            </li>
          </ul>
        </List>
      </Drawer>
    </Box>
  );
}