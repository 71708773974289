import {Box} from "@mui/material";
import * as React from "react";
import {useMemo, useState} from "react";

function FormattedNumber(props: { n: string, sign?: string }) {
  const [value, setValue] = useState(props.n);

  function formatter(v: string): string {
    return parseInt(v) >= 1 ? Intl.NumberFormat('en-US').format(parseFloat(v)) : v.toString()
  }

  return <Box component='span'
              sx={{display: 'inline-block', direction: 'ltr', textAlign: 'left'}}>{props.sign} {formatter(value)}</Box>
}

function detectPrecision(n: string, decimals?: number): string {
  let f = parseFloat(n);
  let d = 2;
  if(decimals !== undefined){
    d = decimals;
  }

  if (f === 0) return '0';

  if (f >= 1) return f.toFixed(d);

  let p = n.split(".");

  let i = 0;
  for (; i < p[1].length; i++) {
    if (parseInt(p[1][i]) === 0) continue;
    break;
  }


  return '0.' + p[1].slice(0, Math.min(p[1].length - 1, i + 3));


}

export default function NumberFormat(props: { price: string, decimals?: number }) {
  const {
    price,
    decimals
  } = props;

  const priceFloated = useMemo(() => detectPrecision(price, decimals), [price]);

  return (
    <Box component='span' sx={{display: 'inline-block', direction: 'ltr', textAlign: 'left'}}>
      <FormattedNumber n={priceFloated}/>
    </Box>
  )
}
