import {useParams} from "react-router-dom";
import {Box, Grid} from "@mui/material";
import {default as ExchangesComponent} from "../../components/Exchanges";
import ExchangesSelector from "../../components/ExchangesSelector";
import {useEffect, useState} from "react";


export default function Exchanges() {
  const {currency} = useParams();
  const [seconds, setSeconds] = useState(60);
  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds <= 0) {
        setSeconds(60);
      } else {
        setSeconds(seconds - 1)
      }
    }, 1000)
    return () => clearInterval(interval);
  })

  return (
    <Grid container id={"ran-exchanges-page"}>
      <Grid item xs={12} md={6} sx={{py: 3, mx: "auto"}}>
        <ExchangesSelector key={'exchange-selector'} currency={currency} />
        <Box key={'timer'} sx={{textAlign: "left", my: 1, px: 1, fontSize: "smaller"}} className={"iransans-fa-num ran-updater"}
          color={"text.secondary"}>
          {seconds} ثانیه تا بروزرسانی قیمت ها
        </Box>
        <ExchangesComponent key={'exchanges'} currency={currency} />
      </Grid>
    </Grid>
  );
}