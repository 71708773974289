import {Box, Grid, Typography} from "@mui/material";

import {useEffect, useState} from "react";
import axios from "axios";
import Loading from '../Loading';
import PersianDate from "../PersianDate/PersianDate";

interface PostInterface {
  id: number,
  slug: string,
  title: {
    rendered: string
  },
  thumbnail: string,
  image: string,
  modified: string,
  content: {
    rendered: string
  },
  author_name: string
}

function PostContent(props: { post: PostInterface }) {
  const publish_word = 'انتشار';
  const author_word = 'نویسنده';
  return (
    <Box className={'single-post'}>
      <Grid container>
        <Grid item xs={12}>
          <Box component='img' sx={{width: '100%'}} src={props.post.image}/>
        </Grid>
        <Grid item xs={12}>
          <Typography sx={{my: 1, mx: 2}}
                      color="grey">{author_word}: {props.post.author_name} - {publish_word}: <PersianDate
            date={props.post.modified}/></Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography fontWeight="900" sx={{my: 1, mx: 2}} variant="h4"
                      component="h2">{props.post.title.rendered}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Box sx={{mb: 1, mt: 2, mx: 2}}>
            <div className="max-width-100 overflow-hidden"
                 dangerouslySetInnerHTML={{__html: props.post.content.rendered}}></div>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default function Post(props: { postId: string | undefined, api: string }) {
  const [post, setPost] = useState<PostInterface>({
    id: 0,
    slug: '',
    title: {
      rendered: '',
    },
    image: '',
    thumbnail: '',
    modified: '',
    content: {
      rendered: ''
    },
    author_name: '',
  });

  const [loading, setLoading] = useState<boolean>(false);

  function loadPost() {
    setLoading(true)
    axios.get(props.api)
      .then(response => response.data)
      .then((data) => {
        setPost(data)
      }).catch(function (err) {

    }).finally(() => {
      setLoading(false)
    });
  }

  useEffect(() => {
    loadPost()
  }, [props.postId])
  return loading ? <Loading/> : <PostContent post={post}/>;
}
