import {SvgIconProps} from "./types";


const ExchangesIcon = (props: SvgIconProps) => {
  const {
    fill,
    type,
    size
  } = props;

  return (
    <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} fill="none" xmlns="http://www.w3.org/2000/svg">
      {type === 'filled' && (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M17.006 4.87304C17.006 4.66639 17.1748 4.5 17.3742 4.5H17.5V5.30689L17.2581 5.22647C17.1147 5.1777 17.0718 5.14019 17.0558 5.12068C17.0472 5.11027 17.006 5.05888 17.006 4.87304Z" fill={fill}/>
          <path d="M18.5 7.5V6.69311L18.7419 6.77353C18.8822 6.82125 18.9263 6.85955 18.9434 6.8804C18.9534 6.89267 18.994 6.94526 18.994 7.12696C18.994 7.33717 18.8273 7.5 18.6257 7.5H18.5Z" fill={fill}/>
          <path fillRule="evenodd" clipRule="evenodd" d="M23 6C23 8.76142 20.7614 11 18 11C15.2386 11 13 8.76142 13 6C13 3.23858 15.2386 1 18 1C20.7614 1 23 3.23858 23 6ZM17.3742 3.5H17.5V3.25C17.5 2.97386 17.7239 2.75 18 2.75C18.2761 2.75 18.5 2.97386 18.5 3.25L18.5 3.5C19.3253 3.5 20 4.16716 20 4.99857C20 5.27472 19.7761 5.49857 19.5 5.49857C19.2239 5.49857 19 5.27472 19 4.99857C19 4.723 18.7765 4.5 18.5 4.5L18.5 5.63931L19.0618 5.82606C19.2738 5.89796 19.5259 6.01356 19.7168 6.24656C19.9148 6.48822 19.994 6.7894 19.994 7.12696C19.994 7.88679 19.3823 8.5 18.6257 8.5H18.5V8.75C18.5 9.02614 18.2761 9.25 18 9.25C17.7239 9.25 17.5 9.02614 17.5 8.75L17.5 8.5C16.6747 8.5 16 7.83284 16 7.00143C16 6.72528 16.2239 6.50143 16.5 6.50143C16.7761 6.50143 17 6.72528 17 7.00143C17 7.277 17.2235 7.5 17.5 7.5L17.5 6.36069L16.9382 6.17394C16.7293 6.1031 16.476 5.98957 16.2841 5.75664C16.0846 5.51456 16.006 5.21216 16.006 4.87304C16.006 4.12106 16.6156 3.5 17.3742 3.5Z" fill={fill}/>
          <path d="M7.57819 2.91194C4.8091 3.55437 2.75 6.03342 2.75 9C2.75 9.41421 2.41421 9.75 2 9.75C1.58579 9.75 1.25 9.41421 1.25 9C1.25 4.71579 4.71579 1.25 9 1.25C9.2702 1.25 9.51952 1.39534 9.65265 1.63048C9.78578 1.86561 9.78214 2.15417 9.64312 2.38587L8.59312 4.13587C8.38001 4.49106 7.91931 4.60623 7.56413 4.39312C7.20894 4.18001 7.09377 3.71931 7.30688 3.36413L7.57819 2.91194Z" fill={fill}/>
          <path d="M12 15.7V15.81C12 16.09 11.78 16.31 11.5 16.31H2.5C2.22 16.31 2 16.09 2 15.81V15.7C2 13.94 2.44 13.5 4.22 13.5H9.78C11.56 13.5 12 13.94 12 15.7Z" fill={fill}/>
          <path d="M2.5 17.3101C2.22 17.3101 2 17.5301 2 17.8101V19.8001C2 21.5601 2.44 22.0001 4.22 22.0001H9.78C11.56 22.0001 12 21.5601 12 19.8001V17.8101C12 17.5301 11.78 17.3101 11.5 17.3101H2.5Z" fill={fill}/>
          <path d="M22.75 15C22.75 14.5858 22.4142 14.25 22 14.25C21.5858 14.25 21.25 14.5858 21.25 15C21.25 17.9666 19.1909 20.4456 16.4218 21.0881L16.6931 20.6359C16.9062 20.2807 16.7911 19.82 16.4359 19.6069C16.0807 19.3938 15.62 19.5089 15.4069 19.8641L14.3569 21.6141C14.2179 21.8458 14.2142 22.1344 14.3474 22.3695C14.4805 22.6047 14.7298 22.75 15 22.75C19.2842 22.75 22.75 19.2842 22.75 15Z" fill={fill}/>
        </svg>

      )}
      {type === 'outlined' && (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M17.5 3.5H17.3742C16.6156 3.5 16.006 4.12106 16.006 4.87304C16.006 5.21216 16.0846 5.51456 16.2841 5.75664C16.476 5.98957 16.7293 6.1031 16.9382 6.17394L17.5 6.36069L17.5 7.5C17.2235 7.5 17 7.277 17 7.00143C17 6.72528 16.7761 6.50143 16.5 6.50143C16.2239 6.50143 16 6.72528 16 7.00143C16 7.83284 16.6747 8.5 17.5 8.5L17.5 8.75C17.5 9.02614 17.7239 9.25 18 9.25C18.2761 9.25 18.5 9.02614 18.5 8.75V8.5H18.6257C19.3823 8.5 19.994 7.88679 19.994 7.12696C19.994 6.7894 19.9148 6.48822 19.7168 6.24656C19.5259 6.01356 19.2738 5.89796 19.0618 5.82606L18.5 5.63931L18.5 4.5C18.7765 4.5 19 4.723 19 4.99857C19 5.27472 19.2239 5.49857 19.5 5.49857C19.7761 5.49857 20 5.27472 20 4.99857C20 4.16716 19.3253 3.5 18.5 3.5L18.5 3.25C18.5 2.97386 18.2761 2.75 18 2.75C17.7239 2.75 17.5 2.97386 17.5 3.25V3.5ZM17.3742 4.5C17.1748 4.5 17.006 4.66639 17.006 4.87304C17.006 5.05888 17.0472 5.11027 17.0558 5.12068C17.0719 5.14019 17.1147 5.1777 17.2581 5.22647L17.5 5.30689V4.5H17.3742ZM18.5 6.69311V7.5H18.6257C18.8273 7.5 18.994 7.33717 18.994 7.12696C18.994 6.94526 18.9534 6.89267 18.9434 6.8804C18.9263 6.85955 18.8822 6.82125 18.7419 6.77353L18.5 6.69311Z" fill={fill}/>
          <path fillRule="evenodd" clipRule="evenodd" d="M18 0.25C14.8244 0.25 12.25 2.82436 12.25 6C12.25 9.17564 14.8244 11.75 18 11.75C21.1756 11.75 23.75 9.17564 23.75 6C23.75 2.82436 21.1756 0.25 18 0.25ZM13.75 6C13.75 3.65279 15.6528 1.75 18 1.75C20.3472 1.75 22.25 3.65279 22.25 6C22.25 8.34721 20.3472 10.25 18 10.25C15.6528 10.25 13.75 8.34721 13.75 6Z" fill={fill}/>
          <path d="M7.57821 2.91194C4.8091 3.55436 2.75 6.03342 2.75 9C2.75 9.41421 2.41421 9.75 2 9.75C1.58579 9.75 1.25 9.41421 1.25 9C1.25 4.71579 4.71579 1.25 9 1.25C9.2702 1.25 9.51952 1.39534 9.65265 1.63047C9.78578 1.8656 9.78214 2.15417 9.64312 2.38587L8.59313 4.13587C8.38002 4.49105 7.91933 4.60623 7.56414 4.39312C7.20896 4.18001 7.09378 3.71932 7.30689 3.36413L7.57821 2.91194Z" fill={fill}/>
          <path fillRule="evenodd" clipRule="evenodd" d="M1.25 15.7V19.81C1.25 20.6843 1.34096 21.5334 1.91415 22.1023C2.48529 22.6692 3.33676 22.76 4.21997 22.76H9.78003C10.6632 22.76 11.5147 22.6692 12.0858 22.1023C12.659 21.5334 12.75 20.6843 12.75 19.81V15.7C12.75 14.8257 12.659 13.9766 12.0858 13.4077C11.5147 12.8408 10.6632 12.75 9.78003 12.75H4.21997C3.33676 12.75 2.48529 12.8408 1.91415 13.4077C1.34096 13.9766 1.25 14.8257 1.25 15.7ZM2.97082 14.4723C2.87902 14.5634 2.75 14.8143 2.75 15.7V16.3101H11.25V15.7C11.25 14.8143 11.121 14.5634 11.0292 14.4723C10.9353 14.3792 10.6768 14.25 9.78003 14.25H4.21997C3.32318 14.25 3.06467 14.3792 2.97082 14.4723ZM11.25 19.81V17.8101H2.75V19.81C2.75 20.6957 2.87902 20.9466 2.97082 21.0377C3.06467 21.1308 3.32318 21.26 4.21997 21.26H9.78003C10.6768 21.26 10.9353 21.1308 11.0292 21.0377C11.121 20.9466 11.25 20.6957 11.25 19.81Z" fill={fill}/>
          <path d="M22.75 15C22.75 14.5858 22.4142 14.25 22 14.25C21.5858 14.25 21.25 14.5858 21.25 15C21.25 17.9666 19.1909 20.4456 16.4218 21.0881L16.6931 20.6359C16.9062 20.2807 16.791 19.82 16.4359 19.6069C16.0807 19.3938 15.62 19.5089 15.4069 19.8641L14.3569 21.6141C14.2179 21.8458 14.2142 22.1344 14.3474 22.3695C14.4805 22.6047 14.7298 22.75 15 22.75C19.2842 22.75 22.75 19.2842 22.75 15Z" fill={fill}/>
        </svg>
      )}
    </svg>
  );
};

export default ExchangesIcon;