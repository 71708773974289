import {Box} from "@mui/material";
import * as React from "react";
import {PostInterface} from "../../types";
import PostItem from "../PostItem/PostItem";

const PostsList = (props: { posts: PostInterface[], singlePath: string }) => {
  return (
    <Box>
      {props.posts.map((post, index) => (
        <PostItem key={index} post={post} singlePath={props.singlePath}/>
      ))}
    </Box>
  );
}

export default PostsList;