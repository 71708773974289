import {createSvgIcon} from "@mui/material";

const AboutUsIcon = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" fill="inherit" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.0498 9.57661C9.0498 7.99344 10.4438 6.84998 11.9998 6.84998C13.5558 6.84998 14.9498 7.99344 14.9498 9.57661C14.9498 10.7937 14.0704 11.4169 13.5069 11.7628C12.9634 12.1002 12.7498 12.3337 12.7498 12.7082V12.9582C12.7498 13.3724 12.414 13.7082 11.9998 13.7082C11.5856 13.7082 11.2498 13.3724 11.2498 12.9582V12.7082C11.2498 11.4637 12.1492 10.8399 12.7177 10.4872L12.7209 10.4853C13.2442 10.1642 13.4498 9.93009 13.4498 9.57661C13.4498 8.96886 12.8824 8.34998 11.9998 8.34998C11.1172 8.34998 10.5498 8.96886 10.5498 9.57661C10.5498 9.99083 10.214 10.3266 9.7998 10.3266C9.38559 10.3266 9.0498 9.99083 9.0498 9.57661Z" fill="inherit"/>
      <path d="M11.9944 14.8042C11.4421 14.8042 10.9944 15.2519 10.9944 15.8042C10.9944 16.3565 11.4421 16.8042 11.9944 16.8042H12.0063C12.5586 16.8042 13.0063 16.3565 13.0063 15.8042C13.0063 15.2519 12.5586 14.8042 12.0063 14.8042H11.9944Z" fill="inherit"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M2.08301 12C2.08301 6.52322 6.52285 2.08337 11.9997 2.08337C17.4765 2.08337 21.9163 6.52322 21.9163 12C21.9163 17.4769 17.4765 21.9167 11.9997 21.9167C6.52285 21.9167 2.08301 17.4769 2.08301 12ZM11.9997 3.58337C7.35128 3.58337 3.58301 7.35164 3.58301 12C3.58301 16.6484 7.35128 20.4167 11.9997 20.4167C16.6481 20.4167 20.4163 16.6484 20.4163 12C20.4163 7.35164 16.6481 3.58337 11.9997 3.58337Z" fill="inherit"/>
  </svg>

  ,
  'AboutUs'
);

export default AboutUsIcon;
