import CurrencySelector from "./CurrencySelector";
import {Stack} from "@mui/material";
import Box from "@mui/material/Box";
import * as React from "react";
import {useEffect, useState} from "react";
import {CurrencyCalculatorProps, ItemInterface} from "./types";
import axios from "axios";
import {transformItems} from "./functions";
import DoubleArrowIcon from "../Icons/DoubleArrowIcon";


const CryptoToCryptoCalculator = (props: CurrencyCalculatorProps) => {
  const [sources, setSources] = useState<ItemInterface[]>([]);
  const [selectedSource, setSelectedSource] = useState<ItemInterface | null>(null);
  const [sourceQuery, setSourceQuery] = useState('');
  const [sourceLoading, setSourceLoading] = useState(false);
  const [targets, setTargets] = useState<ItemInterface[]>([]);
  const [selectedTarget, setSelectedTarget] = useState<ItemInterface | null>(null);
  const [targetLoading, setTargetLoading] = useState(false);
  const [targetQuery, setTargetQuery] = useState('');
  const [sourceValue, setSourceValue] = useState(1);
  const [targetValue, setTargetValue] = useState(0);

  const {
    onLoad,
    onSourceItemChanged,
    onTargetItemChanged,
    onSourceValueChanged,
    onTargetValueChanged
  } = props;

  useEffect(() => {
    let url = `https://appapi.ramzarz.news/api/cryptocurrency/calculator`;
    if (sourceQuery) {
      url = `https://appapi.ramzarz.news/api/cryptocurrency/calculator?search=${sourceQuery}`;
    }
    setSourceLoading(true);
    axios.get(url)
      .then(response => response.data)
      .then((data) => {
        setSources(transformItems(data));
      })
      .finally(() => setSourceLoading(false))
  }, [sourceQuery])

  useEffect(() => {
    if (!selectedSource && sources.length) {
      setSelectedSource(sources[0])
      if(!!onSourceItemChanged){
        onSourceItemChanged(sources[0]);
      }
    }
  }, [sources, selectedSource])

  useEffect(() => {
    let url = `https://appapi.ramzarz.news/api/cryptocurrency/calculator`;
    if (targetQuery) {
      url = `https://appapi.ramzarz.news/api/cryptocurrency/calculator?search=${targetQuery}`;
    }
    setTargetLoading(true);
    axios.get(url)
      .then(response => response.data)
      .then((data) => {
        setTargets(transformItems(data));
      })
      .finally(() => setTargetLoading(false))
  }, [targetQuery])

  useEffect(() => {
    if (!selectedTarget && targets.length > 1) {
      setSelectedTarget(targets[1])
      if(!!onTargetItemChanged){
        onTargetItemChanged(targets[1])
      }
    }
  }, [targets, selectedTarget])

  useEffect(() => {
    setSourceValue(sourceValue);
    if(!!onSourceValueChanged){
      onSourceValueChanged(sourceValue);
    }
    const newTargetValue = (selectedSource !== null && selectedTarget !== null) ? (sourceValue * (selectedSource.price_usd / selectedTarget.price_usd)) : 0;

    setTargetValue(newTargetValue);
    if(!!onTargetValueChanged){
      onTargetValueChanged(newTargetValue)
    }
  }, [selectedSource, selectedTarget, sourceValue])

  useEffect(() => {
    onLoad(sourceLoading || targetLoading)
  }, [sourceLoading, targetLoading, onLoad])

  const handleSourceChange = (v: number) => {
    setSourceValue(v);
    if(!!onSourceValueChanged){
      onSourceValueChanged(v);
    }
    const newTargetValue = (selectedSource !== null && selectedTarget !== null) ? (v * (selectedSource.price_usd / selectedTarget.price_usd)) : 0;
    setTargetValue(newTargetValue);

    if(!!onTargetValueChanged){
      setTargetValue(newTargetValue);
    }
  }
  const handleTargetChange = (v: number) => {
    const newSourceValue = (selectedSource !== null && selectedTarget !== null) ? (v * (selectedTarget.price_usd / selectedSource.price_usd)) : 0;

    setSourceValue(newSourceValue);
    setTargetValue(v);

    if(!!onSourceValueChanged){
      onSourceValueChanged(newSourceValue);
    }

    if(!!onTargetValueChanged){
      setTargetValue(v);
    }
  }
  return (
    <>
      <CurrencySelector
        items={sources}
        selectedItem={selectedSource}
        value={sourceValue}
        onSearch={(q) => setSourceQuery(q)}
        onChange={handleSourceChange}
        onSelect={(item) => {
          setSelectedSource(item)
          if(!!onSourceItemChanged){
            onSourceItemChanged(item);
          }
        }}
        defaultValue={1}
        decimals={6}
        loading={sourceLoading}
      />
      <Stack direction={'row'} justifyContent={'center'}>
        <Box sx={{py: 1}}>
          <DoubleArrowIcon sx={{fontSize: '24px'}} />
        </Box>
      </Stack>
      <CurrencySelector
        items={targets}
        value={targetValue}
        selectedItem={selectedTarget}
        onSearch={(q) => setTargetQuery(q)}
        onChange={handleTargetChange}
        onSelect={(item) => {
          setSelectedTarget(item)
          if(!!onTargetItemChanged){
            onTargetItemChanged(item)
          }
        }}
        defaultValue={1}
        loading={targetLoading}
        decimals={6}
      />
    </>
  );
}

export default CryptoToCryptoCalculator;