import {createSvgIcon} from "@mui/material";

const AttachIcon = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" fill="inherit" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.80056 6.77394C9.52093 6.49427 9.52093 6.04082 9.80056 5.76115L10.82 4.74161C13.1457 2.41549 16.9408 2.42344 19.2587 4.74161C21.5844 7.06772 21.5765 10.8634 19.2587 13.1815L18.2393 14.2011C17.9596 14.4808 17.5062 14.4808 17.2266 14.2011C16.947 13.9214 16.947 13.468 17.2266 13.1883L18.246 12.1687C20.0058 10.4087 20.0113 7.51996 18.246 5.7544C16.4863 3.99439 13.5979 3.98883 11.8326 5.7544L10.8132 6.77394C10.5336 7.05362 10.0802 7.05362 9.80056 6.77394Z" fill="inherit"/>
    <path d="M4.74388 19.2584C2.41871 16.9329 2.41871 13.144 4.74388 10.8185L5.75652 9.80567C6.03616 9.52599 6.48953 9.52599 6.76917 9.80567C7.0488 10.0853 7.0488 10.5388 6.76917 10.8185L5.75652 11.8313C3.99062 13.5974 3.99062 16.4794 5.75652 18.2456C7.51628 20.0056 10.4046 20.0112 12.1699 18.2456L13.1826 17.2328C13.4622 16.9531 13.9156 16.9531 14.1952 17.2328C14.4748 17.5125 14.4748 17.9659 14.1952 18.2456L13.1826 19.2584C10.8568 21.5845 7.0617 21.5766 4.74388 19.2584Z" fill="inherit"/>
    <path d="M8.79456 14.1945C8.51492 14.4741 8.51492 14.9276 8.79456 15.2073C9.07419 15.4869 9.52756 15.4869 9.8072 15.2073L15.208 9.80571C15.4876 9.52603 15.4876 9.07259 15.208 8.79291C14.9283 8.51324 14.475 8.51324 14.1953 8.79291L8.79456 14.1945Z" fill="inherit"/>
  </svg>
  ,
  'Attach'
);

export default AttachIcon;
