import { useRouteError } from "react-router-dom";

export default function ErrorPage() {
  const error = useRouteError();

  return (
    <div id="error-page">
      <h1>کد خطا: {error.statusCode}</h1>
      <p>مشکلی در برقراری ارتباط پیش آمده است.</p>
      <p>
        <i>{error.statusText || error.message}</i>
      </p>
    </div>
  );
}