import {createSvgIcon} from "@mui/material";

const SwitchThemeIcon = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" fill="inherit" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 1C12.5523 1 13 1.44772 13 2V2.08C13 2.63228 12.5523 3.08 12 3.08C11.4477 3.08 11 2.63228 11 2.08V2C11 1.44772 11.4477 1 12 1Z" fill="inherit"/>
    <path d="M4.15289 4.15289C4.54342 3.76237 5.17658 3.76237 5.56711 4.15289L5.69711 4.28289C6.08763 4.67342 6.08763 5.30658 5.69711 5.69711C5.30658 6.08763 4.67342 6.08763 4.28289 5.69711L4.15289 5.56711C3.76237 5.17658 3.76237 4.54342 4.15289 4.15289Z" fill="inherit"/>
    <path d="M19.8471 4.15289C20.2376 4.54342 20.2376 5.17658 19.8471 5.56711L19.7171 5.69711C19.3266 6.08763 18.6934 6.08763 18.3029 5.69711C17.9124 5.30658 17.9124 4.67342 18.3029 4.28289L18.4329 4.15289C18.8234 3.76237 19.4566 3.76237 19.8471 4.15289Z" fill="inherit"/>
    <path d="M1 12C1 11.4477 1.44772 11 2 11H2.08C2.63228 11 3.08 11.4477 3.08 12C3.08 12.5523 2.63228 13 2.08 13H2C1.44772 13 1 12.5523 1 12Z" fill="inherit"/>
    <path d="M20.92 12C20.92 11.4477 21.3677 11 21.92 11H22C22.5523 11 23 11.4477 23 12C23 12.5523 22.5523 13 22 13H21.92C21.3677 13 20.92 12.5523 20.92 12Z" fill="inherit"/>
    <path d="M5.69711 18.3029C6.08763 18.6934 6.08763 19.3266 5.69711 19.7171L5.56711 19.8471C5.17658 20.2376 4.54342 20.2376 4.15289 19.8471C3.76237 19.4566 3.76237 18.8234 4.15289 18.4329L4.28289 18.3029C4.67342 17.9124 5.30658 17.9124 5.69711 18.3029Z" fill="inherit"/>
    <path d="M18.3029 18.3029C18.6934 17.9124 19.3266 17.9124 19.7171 18.3029L19.8471 18.4329C20.2376 18.8234 20.2376 19.4566 19.8471 19.8471C19.4566 20.2376 18.8234 20.2376 18.4329 19.8471L18.3029 19.7171C17.9124 19.3266 17.9124 18.6934 18.3029 18.3029Z" fill="inherit"/>
    <path d="M12 20.92C12.5523 20.92 13 21.3677 13 21.92V22C13 22.5523 12.5523 23 12 23C11.4477 23 11 22.5523 11 22V21.92C11 21.3677 11.4477 20.92 12 20.92Z" fill="inherit"/>
    <path d="M15.5355 15.5355C14.5979 16.4732 13.3261 17 12 17L12 7C13.3261 7 14.5979 7.52678 15.5355 8.46447C16.4732 9.40215 17 10.6739 17 12C17 13.3261 16.4732 14.5979 15.5355 15.5355Z" fill="inherit"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M4.75 12C4.75 7.99594 7.99594 4.75 12 4.75C16.0041 4.75 19.25 7.99594 19.25 12C19.25 16.0041 16.0041 19.25 12 19.25C7.99594 19.25 4.75 16.0041 4.75 12ZM12 6.25C8.82436 6.25 6.25 8.82436 6.25 12C6.25 15.1756 8.82436 17.75 12 17.75C15.1756 17.75 17.75 15.1756 17.75 12C17.75 8.82436 15.1756 6.25 12 6.25Z" fill="inherit"/>
  </svg>
  ,
  'SwitchTheme'
);

export default SwitchThemeIcon;
