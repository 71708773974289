import {Box} from "@mui/material";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import {memo, useEffect, useState} from "react";


const Arrow = memo(function Arrow(props: {type: 'up' | 'down' | 'none'})  {
  const {
    type
  } = props;

  if(type === 'up'){
    return <ArrowRightAltIcon fontSize='small' color='success' sx={{transform: 'rotate(-90deg)', verticalAlign: 'sub'}}/>
  }else if(type === 'down'){
    return <ArrowRightAltIcon fontSize='small' color='error' sx={{transform: 'rotate(90deg)', verticalAlign: 'sub'}}/>
  }else{
    return <></>
  }
})
const Percentage = memo(function Percentage(props: { value: string, arrow?: boolean, compact?: boolean, decimals?: number }) {
  const {
    value,
    arrow,
    compact,
    decimals
  } = props;

  const [arrowType, setArrowType] = useState<'up' | 'down' | 'none'>('none');
  const [color, setColor] = useState<'red' | 'green' | 'inherit'>('inherit');
  const [formattedValue, setFormattedValue] = useState<string>('0.0');

  useEffect(() => {
    if(!arrow){
      setArrowType('none');
    }else{
      let pf = parseFloat(value);
      if(pf > 0){
        setArrowType('up');
      }else if(pf < 0){
        setArrowType('down');
      }else{
        setArrowType('none');
      }
    }
  }, [arrow, value]);

  useEffect(() => {
    let pf = parseFloat(value);
    if (pf > 0) {
      setColor('green')
    } else if (pf < 0) {
      setColor('red')
    } else {
      setColor('inherit');
    }
  }, [value])

  useEffect(() => {
    let v;
    if (!!compact && parseFloat(value) >= 1000000000) {
      let f = parseFloat(value) / 1000000000;
      let formatted = Intl.NumberFormat('en-US').format(parseFloat(f.toFixed(2).toString()));
      v = formatted + 'G';
    } else if (!!compact && parseFloat(value) >= 1000000) {
      let f = parseFloat(value) / 1000000;
      let formatted = Intl.NumberFormat('en-US').format(parseFloat(f.toFixed(2).toString()));
      v = formatted + 'M';
    } else if (!!compact && parseFloat(value) >= 1000) {
      let f = parseFloat(value) / 1000;
      let formatted = Intl.NumberFormat('en-US').format(parseFloat(f.toFixed(2).toString()));
      v = formatted + 'K';
    } else if(!!compact && parseFloat(value) < 1000){
      let f = parseFloat(value);

      // This trick remove minus and plus before zero
      let s = Number(f.toFixed(2))*1+0;

      v = Intl.NumberFormat('en-US').format(parseFloat(s.toString()));
    } else {
      if(decimals !== undefined){
        v = Intl.NumberFormat('en-US').format(
          parseFloat(
            parseFloat(value).toFixed(decimals)
          )
        );
      }else{
        v = Intl.NumberFormat('en-US').format(
          parseFloat(value)
        );
      }
    }

    setFormattedValue(v);
  }, [compact, value, decimals]);

  return (<Box component='span' sx={{color: color, display: 'inline-block', direction: 'ltr'}}>{formattedValue} % <Arrow type={arrowType} /> </Box>);
});

export default Percentage;