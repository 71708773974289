import CurrencySelector from "./CurrencySelector";
import {Stack} from "@mui/material";
import Box from "@mui/material/Box";
import * as React from "react";
import {useEffect, useState} from "react";
import {CurrencyCalculatorProps, ItemInterface} from "./types";
import axios from "axios";
import IranImg from '../../assets/iran.png';
import UsaImg from '../../assets/usa.png';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import {transformItems} from "./functions";
import DoubleArrowIcon from "../Icons/DoubleArrowIcon";


const CryptoToFiatCalculator = (props: CurrencyCalculatorProps) => {
  const [sources, setSources] = useState<ItemInterface[]>([]);
  const [selectedSource, setSelectedSource] = useState<ItemInterface | null>(null);
  const [sourceQuery, setSourceQuery] = useState('');
  const [sourceLoading, setSourceLoading] = useState(false);
  const [targets, setTargets] = useState<ItemInterface[]>([]);
  const [selectedTarget, setSelectedTarget] = useState<ItemInterface | null>(null);
  const [targetLoading, setTargetLoading] = useState(false);
  const [targetQuery, setTargetQuery] = useState('');
  const [sourceValue, setSourceValue] = useState(1);
  const [targetValue, setTargetValue] = useState(0);
  const [usdtLoaded, setUsdtLoaded] = useState<boolean>(false);

  const {
    onLoad,
    onUsdtItemChanged,
    onSourceItemChanged,
    onTargetItemChanged,
    onSourceValueChanged,
    onTargetValueChanged
  } = props;

  useEffect(() => {
    let url = `https://appapi.ramzarz.news/api/cryptocurrency/calculator`;
    if (sourceQuery) {
      url = `https://appapi.ramzarz.news/api/cryptocurrency/calculator?search=${sourceQuery}`;
    }

    setSourceLoading(true);
    axios.get(url)
      .then(response => response.data)
      .then((data) => {
        setSources(transformItems(data));
      })
      .finally(() => setSourceLoading(false))
  }, [sourceQuery])

  useEffect(() => {
    if (!selectedSource && sources.length) {
      setSelectedSource(sources[0])
      if(!!onSourceItemChanged){
        onSourceItemChanged(sources[0]);
      }
    }
  }, [sources, selectedSource])

  useEffect(() => {
    if (!usdtLoaded) {
      let usdt = sources.find((source) => {
        return source.symbol.toUpperCase() === 'USDT';
      })
      let priceToman = 1;
      if (usdt) {
        priceToman = usdt.price_toman;
        setUsdtLoaded(true);
        if(!!onUsdtItemChanged){
          onUsdtItemChanged(usdt);
        }
      }

      let data: ItemInterface[] = [
        {
          "id": '1',
          "name": "دلار آمریکا",
          "symbol": "USD",
          "icon": UsaImg,
          "price_usd": 1,
          "price_toman": priceToman,
          decimals: 2
        },
        {
          "id": '2',
          "name": "تومان",
          "symbol": "IRT",
          "icon": IranImg,
          "price_usd": 1 / priceToman,
          "price_toman": 1,
          decimals: 0,
        }
      ];
      setTargetLoading(true);
      setTargets(data);
      setTargetLoading(false);
    }

  }, [targetQuery, sources, usdtLoaded])

  useEffect(() => {
    if (!selectedTarget && targets.length > 1) {
      setSelectedTarget(targets[0])
      if(!!onTargetItemChanged){
        onTargetItemChanged(targets[0]);
      }
    }
  }, [targets, selectedTarget])

  useEffect(() => {
    let tValue = (selectedSource !== null && selectedTarget !== null) ? ((sourceValue * selectedSource.price_usd) / selectedTarget.price_usd) : 0;
    setSourceValue(sourceValue);
    setTargetValue(tValue);

    if(!!onSourceValueChanged){
      onSourceValueChanged(sourceValue)
    }
    if(!!onTargetValueChanged){
      onTargetValueChanged(tValue);
    }
  }, [selectedSource, selectedTarget, sourceValue]);

  useEffect(() => {
    onLoad(sourceLoading || targetLoading)
  }, [sourceLoading, targetLoading, onLoad]);

  const handleSourceChange = (v: number) => {
    let tValue = (selectedSource !== null && selectedTarget !== null) ? ((v * selectedSource.price_usd) / selectedTarget.price_usd) : 0;
    setSourceValue(v);
    setTargetValue(tValue);

    if(!!onSourceValueChanged){
      onSourceValueChanged(v)
    }
    if(!!onTargetValueChanged){
      onTargetValueChanged(tValue);
    }
  }
  const handleTargetChange = (v: number) => {
    let sValue;
    if (selectedSource !== null && selectedTarget !== null) {
      sValue = ((v * selectedTarget.price_usd) / selectedSource.price_usd);
    } else {
      sValue = 0;
    }

    setSourceValue(sValue);
    setTargetValue(v);

    if(!!onSourceValueChanged){
      onSourceValueChanged(sValue)
    }
    if(!!onTargetValueChanged){
      onTargetValueChanged(v);
    }
  }
  return (
    <>
      <CurrencySelector
        items={sources}
        selectedItem={selectedSource}
        value={sourceValue}
        onSearch={(q) => setSourceQuery(q)}
        onChange={handleSourceChange}
        onSelect={(item) => {
          setSelectedSource(item)
          if(!!onSourceItemChanged){
            onSourceItemChanged(item);
          }
        }}
        defaultValue={1}
        decimals={6}
        loading={sourceLoading}
      />
      <Stack direction={'row'} justifyContent={'center'} className={'ran-swap'}>
        <Box sx={{py: 1}}>
          <DoubleArrowIcon sx={{fontSize: '24px'}} />
        </Box>
      </Stack>
      <CurrencySelector
        items={targets}
        value={targetValue}
        selectedItem={selectedTarget}
        onSearch={(q) => setTargetQuery(q)}
        onChange={handleTargetChange}
        onSelect={(item) => {
          setSelectedTarget(item)
          if(!!onTargetItemChanged){
            onTargetItemChanged(item)
          }
        }}
        defaultValue={1}
        loading={targetLoading}
        decimals={targetValue > 1 ? Number(selectedTarget?.decimals) : 6}
        popoverTitle={'ارزهای رایج'}
      />
    </>
  );
}

export default CryptoToFiatCalculator;