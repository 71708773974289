import {createSvgIcon} from "@mui/material";

const InfoIcon = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" fill="inherit" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 7.25C12.4142 7.25 12.75 7.58579 12.75 8V13C12.75 13.4142 12.4142 13.75 12 13.75C11.5858 13.75 11.25 13.4142 11.25 13V8C11.25 7.58579 11.5858 7.25 12 7.25Z" fill="inherit"/>
    <path d="M11.9946 15C11.4423 15 10.9946 15.4477 10.9946 16C10.9946 16.5523 11.4423 17 11.9946 17H12.0036C12.5559 17 13.0036 16.5523 13.0036 16C13.0036 15.4477 12.5559 15 12.0036 15H11.9946Z" fill="inherit"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M1.25 12C1.25 6.08579 6.08579 1.25 12 1.25C17.9142 1.25 22.75 6.08579 22.75 12C22.75 17.9142 17.9142 22.75 12 22.75C6.08579 22.75 1.25 17.9142 1.25 12ZM12 2.75C6.91421 2.75 2.75 6.91421 2.75 12C2.75 17.0858 6.91421 21.25 12 21.25C17.0858 21.25 21.25 17.0858 21.25 12C21.25 6.91421 17.0858 2.75 12 2.75Z" fill="inherit"/>
  </svg>,
  'Info'
);

export default InfoIcon;
