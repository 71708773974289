import {default as RootAppBar} from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import {Box, IconButton} from "@mui/material";
import {ArrowBack} from "@mui/icons-material";

export default function SimpleAppBar() {

  return <RootAppBar sx={{boxShadow: 0, borderBottom: "1px solid #0000001f"}} position="static" color="secondary">
    <Toolbar variant="dense">
      <Box sx={{flexGrow: 1}}>
        <img width={128} src="https://app.ramzarz.news/img/logo.svg"/>
      </Box>
      <IconButton
        href={"/currencies"}
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        color="inherit"
      >
        <ArrowBack fontSize={"large"}/>
      </IconButton>
    </Toolbar>
  </RootAppBar>
}
