import {createSvgIcon} from "@mui/material";

const DoubleArrowIcon = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" fill="inherit" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.52 2.95913C15.2269 2.66653 14.752 2.667 14.4594 2.96019C14.312 3.10787 14.239 3.30164 14.2403 3.49499L14.2402 3.5V20.5C14.2402 20.9142 14.576 21.25 14.9902 21.25C15.4044 21.25 15.7402 20.9142 15.7402 20.5V5.2981L19.4804 9.03085C19.7736 9.32345 20.2485 9.32298 20.5411 9.02979C20.8337 8.73661 20.8332 8.26173 20.54 7.96913L15.52 2.95913Z" fill="inherit"/>
    <path d="M4.52004 14.9591C4.22685 14.6665 3.75198 14.667 3.45938 14.9602C3.16677 15.2534 3.16725 15.7282 3.46043 16.0208L8.46422 21.0147C8.60097 21.1596 8.79481 21.25 9.00977 21.25C9.03416 21.25 9.05856 21.2488 9.08287 21.2465C9.25018 21.2303 9.41308 21.1581 9.54109 21.0298C9.69396 20.8766 9.76684 20.6738 9.75977 20.4734V3.5C9.75977 3.08579 9.42398 2.75 9.00977 2.75C8.59555 2.75 8.25977 3.08579 8.25977 3.5V18.6914L4.52004 14.9591Z" fill="inherit"/>
  </svg>
  ,
  'DoubleArrow'
);

export default DoubleArrowIcon;
