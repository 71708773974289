import * as React from "react";
import TextField, {TextFieldProps} from "@mui/material/TextField";
import {styled} from "@mui/material";
import {grey} from "@mui/material/colors";

const StyledTextField = styled(TextField)<TextFieldProps>(({theme}) => ({
  direction: 'ltr',
  textAlign: 'left',
  '& fieldset.MuiOutlinedInput-notchedOutline': {
    borderBottomRightRadius: 0,
    borderTopRightRadius: 0,
    border: 'none',
    top: '-5.5px',
  },
  '& input.MuiInputBase-input': {
    fontSize: '14px',
    fontWeight: '800',
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.mode === 'light' ?
                      grey[100] : grey[900],
    borderBottomLeftRadius: '40px',
    borderTopLeftRadius: '40px',
  }
}));
const NumberInput = (props: TextFieldProps) => {
  return (
    <StyledTextField
      {...props}
      variant='outlined'
      fullWidth
    />
  );
}

export default NumberInput;