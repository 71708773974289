import * as React from "react";
import BottomNavigation from "./components/BottomNavigation";
import {Outlet, useLocation} from "react-router-dom"
import AppBar from "./components/AppBar";
import Box from "@mui/material/Box";
import SimpleAppBar from "./components/SimpleAppBar";

export default function App() {
  const location = useLocation();
  const isSingleCurrencyPage = !!location.pathname.match(/currencies\/.*/);

  return (
      <Box sx={{flexGrow: 1}}>
        {isSingleCurrencyPage ? <SimpleAppBar/> : <AppBar/>}
        <Box sx={{mb: 8}}>
          <Outlet/>
        </Box>
        {!isSingleCurrencyPage && <BottomNavigation/>}
      </Box>
  );
}