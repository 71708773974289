import {Box} from "@mui/material";
import NewsList from "../../components/NewsList";

export default function News() {
  return (
    <Box>
      <NewsList/>
    </Box>
  );
}
