import {Box, Divider, Grid, Paper, Stack, Typography} from "@mui/material";
import PersianDate from "../PersianDate";
import * as React from "react";
import {PostInterface} from "../../types";

const PostItem = (props: { post: PostInterface, singlePath: string }) => {
  return (
    <Paper className={'post-paper'} elevation={0}>
      <Grid container sx={{py: 1}}>
        <Grid item xs={3} className={'post-media'}>
          <a href={props.post.link}>
            <Box component='img'
                 className={'post-img'}
                 src={props.post.thumbnail}
                 sx={{
                   width: '64px',
                   height: '70px',
                   maxHeight: '70px',
                   borderRadius: '3px'
                 }}
            />
          </a>
        </Grid>
        <Grid item xs={9} className={'post-title'}>
          <Stack>
            <Box className={'post-title'}>
              <a className='no-underline' href={props.post.link}>
                <Typography variant='h6' fontWeight='700' color='text.primary'>{props.post.title}</Typography>
              </a>
            </Box>
            <Box className={'post-date'}>
              <Typography fontSize={'0.5rem'} color='text.secondary'><PersianDate date={props.post.modified}/></Typography>
            </Box>
          </Stack>
        </Grid>
      </Grid>
      <Divider/>
    </Paper>
  );
}

export default PostItem;