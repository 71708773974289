import {createSvgIcon} from "@mui/material";

const InstagramIcon = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M7.75 12C7.75 9.65279 9.65279 7.75 12 7.75C14.3472 7.75 16.25 9.65279 16.25 12C16.25 14.3472 14.3472 16.25 12 16.25C9.65279 16.25 7.75 14.3472 7.75 12ZM12 9.25C10.4812 9.25 9.25 10.4812 9.25 12C9.25 13.5188 10.4812 14.75 12 14.75C13.5188 14.75 14.75 13.5188 14.75 12C14.75 10.4812 13.5188 9.25 12 9.25Z" fill="#E57E03"/>
    <path d="M17.6357 6C17.0835 6 16.6357 6.44772 16.6357 7C16.6357 7.55228 17.0835 8 17.6357 8H17.6473C18.1996 8 18.6473 7.55228 18.6473 7C18.6473 6.44772 18.1996 6 17.6473 6H17.6357Z" fill="#E57E03"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M3.09467 3.09467C4.42914 1.7602 6.42603 1.25 9 1.25H15C17.574 1.25 19.5709 1.7602 20.9053 3.09467C22.2398 4.42914 22.75 6.42603 22.75 9V15C22.75 17.574 22.2398 19.5709 20.9053 20.9053C19.5709 22.2398 17.574 22.75 15 22.75H9C6.42603 22.75 4.42914 22.2398 3.09467 20.9053C1.7602 19.5709 1.25 17.574 1.25 15V9C1.25 6.42603 1.7602 4.42914 3.09467 3.09467ZM4.15533 4.15533C3.2398 5.07086 2.75 6.57397 2.75 9V15C2.75 17.426 3.2398 18.9291 4.15533 19.8447C5.07086 20.7602 6.57397 21.25 9 21.25H15C17.426 21.25 18.9291 20.7602 19.8447 19.8447C20.7602 18.9291 21.25 17.426 21.25 15V9C21.25 6.57397 20.7602 5.07086 19.8447 4.15533C18.9291 3.2398 17.426 2.75 15 2.75H9C6.57397 2.75 5.07086 3.2398 4.15533 4.15533Z" fill="#E57E03"/>
  </svg>
  ,
  'Instagram'
);

export default InstagramIcon;
