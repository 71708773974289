import {Box} from "@mui/material";
import IdeasList from "../../components/IdeasList";

export default function Ideas() {
  return (
    <Box>
      <IdeasList/>
    </Box>
  );
}
