import {createSvgIcon} from "@mui/material";

const NewsIcon = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" fill="inherit" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M18.8616 5.14071C17.7652 4.04428 16.6614 3.55534 15.548 3.66462C14.474 3.77005 13.5839 4.41778 12.8609 5.14071L4.98094 13.0207C4.75725 13.2444 4.56439 13.5405 4.42115 13.8255C4.27821 14.1099 4.15369 14.4448 4.10865 14.7661L4.10847 14.7674L3.67922 17.7721L3.679 17.7736C3.56986 18.52 3.77405 19.2303 4.27327 19.7274C4.77141 20.2236 5.48143 20.4262 6.22352 20.324L9.23734 19.8935C9.5544 19.8482 9.88698 19.7231 10.1693 19.5812C10.4534 19.4384 10.7477 19.248 10.9729 19.0301L17.714 12.289C17.7267 12.2771 17.7391 12.2648 17.751 12.252L18.8616 11.1414C19.5845 10.4184 20.2323 9.52831 20.3377 8.45431C20.447 7.34095 19.958 6.23714 18.8616 5.14071ZM16.9942 10.8874L17.8009 10.0807C18.438 9.44364 18.7903 8.86376 18.8449 8.30777C18.8956 7.79112 18.7045 7.10494 17.8009 6.20137C16.8974 5.2978 16.2112 5.10674 15.6945 5.15745C15.1385 5.21203 14.5587 5.5643 13.9216 6.20137L13.1142 7.00875C13.7547 8.81399 15.179 10.2392 16.9942 10.8874ZM11.9638 8.15914L6.0416 14.0814C5.96528 14.1577 5.85815 14.3066 5.76139 14.4991C5.66452 14.6918 5.60919 14.8663 5.59407 14.9747L5.59373 14.9771L5.16373 17.9871L5.16332 17.99C5.11296 18.3331 5.21382 18.5472 5.33177 18.6646C5.45093 18.7833 5.67035 18.8856 6.01724 18.8383L6.01902 18.838L9.0252 18.4086C9.12814 18.3939 9.30056 18.339 9.49571 18.2409C9.68645 18.145 9.84049 18.0372 9.92606 17.9556L15.8444 12.0372C14.1276 11.2482 12.7495 9.87175 11.9638 8.15914Z" fill="inherit"/>
  </svg>
  ,
  'News'
);

export default NewsIcon;
