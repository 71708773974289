import ArticlesList from "../../components/ArticlesList";
import {useLocation} from "react-router-dom";
import {useEffect, useState} from "react";
import TwinTabs from "../../components/TwinTabs/TwinTabs";
import * as React from "react";
import NewsList from "../../components/NewsList";

const TAB1_LABEL = 'اخبار';
const TAB2_LABEL = 'مقالات';

export default function Articles() {
  const {
    pathname
  } = useLocation();
  const [tab, setTab] = useState<number>(1);

  useEffect(() => {
    if(pathname.startsWith('/blog')){
      setTab(0);
    }else{
      setTab(1)
    }
  }, [pathname]);

  return (
    <TwinTabs
      label1={TAB1_LABEL}
      label2={TAB2_LABEL}
      tab1={<NewsList />}
      tab1Path="/blog"
      tab2={<ArticlesList />}
      tab2Path="/articles"
      defaultTab={tab}
    />
  );
}
