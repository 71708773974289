import {Box, Button, Divider, Grid, Paper, Stack, Typography} from "@mui/material";
import Ellipsis from "../Ellipsis";
import * as React from "react";
import {Currency} from "./types";
import Toman from "../Toman";
import DollarPrice from "../DollarPrice";
import Blinker from "../Blinker";
import Percentage from "../Percentage";
import {memo} from "react";

const IconAndNameCol = memo(function IconAndNameCol(props: {
  name: string,
  slug: string,
  cmcId: number,
  symbol: string
}) {
  const {
    name,
    slug,
    cmcId,
    symbol
  } = props;
  return (
    <Grid item xs={3.5} sm={4} sx={{textAlign: 'center'}} justifyContent="center">
      <Stack direction='row'>
        <Box sx={{my: 'auto'}} className={'currency-logo'}>
          <Button sx={{
            padding: 0,
            justifyContent: 'flex-start',
            display: 'inline',
            '&:hover': {backgroundColor: 'inherit'}
          }} component={'a'} disableElevation disableFocusRipple disableRipple variant={'text'}
                  href={`/currencies/${slug.toLowerCase()}`}>
            <img

              src={`/coins/32x32/${cmcId}.png`}
              onError={(event) => {
                event.currentTarget.onerror = null;
                event.currentTarget.src=`https://s2.coinmarketcap.com/static/img/coins/32x32/${cmcId}.png`;
              }}
            />
          </Button>
        </Box>
        <Stack sx={{textAlign: 'left', ml: 1}}>
          <Box>
            <Button sx={{
              padding: 0,
              justifyContent: 'flex-start',
              textTransform: 'capitalize',
              display: 'inline',
              '&:hover': {backgroundColor: 'inherit'}
            }} component={'a'} disableElevation disableFocusRipple disableRipple variant={'text'}
                    className={'currency-detail-link'} href={`/currencies/${slug.toLowerCase()}`}>
              <Ellipsis text={name}/>
            </Button>
          </Box>
          <Box>
            <Button sx={{
              padding: 0,
              justifyContent: 'flex-start',
              display: 'inline',
              '&:hover': {backgroundColor: 'inherit'}
            }} component={'a'} disableElevation disableFocusRipple disableRipple variant={'text'}
                    className={'currency-symbol-link'} href={`/currencies/${slug.toLowerCase()}`}>
              <Typography
                className={'currency-symbol1'}
                sx={{
                  fontSize: '15px',
                  fontWeight: '800'
                }}
                color={(theme) => theme.palette.text.primary}>{symbol.toUpperCase()}</Typography>
            </Button>
          </Box>
        </Stack>
      </Stack>
    </Grid>
  );
});

const PriceCol = memo(function PriceCol(props: {
  raise: -1 | 0 | 1 | undefined
  price: string,
  price_toman: string
}) {
  const {
    raise,
    price,
    price_toman
  } = props;
  return (
    <Grid item className={'currency-price'} xs={5}
          sx={{textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
      <Box className={'dollar-price'}>
        <Blinker mode={raise}>
          <DollarPrice price={price}/>
        </Blinker>
      </Box>
      <Box className={'toman-price'} sx={{direction: 'rtl', color: 'grey'}}>
        <Toman
          irt={price_toman}
          usd={price}
          symbol='تومان'
        />
      </Box>
    </Grid>
  );
})

const PercentageCol = memo(function PercentageCol(props: {
  percentChange24h: string
}){
  const {
    percentChange24h
  } = props;
  return (
    <Grid item className={'changes-24h'} xs={2} sx={{textAlign: 'center', my: '0'}}>
      <Percentage
      value={percentChange24h} compact/>
    </Grid>
  );
})

const RankCol = memo(function RankCol(props: {
  cmcRank: number
}){
  const {
    cmcRank
  } = props;
  return (
    <Grid item xs={1.5} sm={1} sx={{textAlign: 'center', my: '0'}} className={'rank'}>
      <Typography variant='body1'>{cmcRank}</Typography>
    </Grid>
  );
})
export default function CurrencyRow(props: { currency: Currency }) {
  const {
    currency
  } = props;
  return (
    <Paper elevation={0} className={'currency-row'}>
      <Grid container sx={{pt: '10px', pb: '8px', mt: '0!important', mb: '0!important', height: '60px!important'}}>
        <RankCol cmcRank={currency.cmcRank} />
        <IconAndNameCol
          name={currency.name}
          symbol={currency.symbol}
          cmcId={currency.cmcId}
          slug={currency.slug}
        />
        <PriceCol
          raise={currency.raise}
          price={currency.price}
          price_toman={currency.price_toman}
        />
        <PercentageCol percentChange24h={currency.percentChange24h} />
      </Grid>
      <Divider/>
    </Paper>
  );
}
