import {createSvgIcon} from "@mui/material";

const TermsIcon = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" fill="inherit" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.09619 11C7.09619 10.4477 7.54391 10 8.09619 10H8.10517C8.65746 10 9.10517 10.4477 9.10517 11C9.10517 11.5523 8.65746 12 8.10517 12H8.09619C7.54391 12 7.09619 11.5523 7.09619 11Z" fill="inherit"/>
    <path d="M10.8984 10.25C10.4842 10.25 10.1484 10.5858 10.1484 11C10.1484 11.4142 10.4842 11.75 10.8984 11.75H16.3984C16.8127 11.75 17.1484 11.4142 17.1484 11C17.1484 10.5858 16.8127 10.25 16.3984 10.25H10.8984Z" fill="inherit"/>
    <path d="M7.09619 7C7.09619 6.44772 7.54391 6 8.09619 6H8.10517C8.65746 6 9.10517 6.44772 9.10517 7C9.10517 7.55228 8.65746 8 8.10517 8H8.09619C7.54391 8 7.09619 7.55228 7.09619 7Z" fill="inherit"/>
    <path d="M10.8984 6.25C10.4842 6.25 10.1484 6.58579 10.1484 7C10.1484 7.41421 10.4842 7.75 10.8984 7.75H16.3984C16.8127 7.75 17.1484 7.41421 17.1484 7C17.1484 6.58579 16.8127 6.25 16.3984 6.25H10.8984Z" fill="inherit"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M3.89481 2.49637C4.84095 1.48504 6.3285 1.25 8.22 1.25H15.78C17.6712 1.25 19.1594 1.48491 20.1044 2.49685C21.0328 3.49101 21.2449 5.03799 21.24 7.04109V18.31C21.24 19.0349 21.1423 19.6769 20.9306 20.1986C20.7178 20.7228 20.3662 21.1764 19.8397 21.4036C19.3078 21.6332 18.7382 21.5712 18.2186 21.3471C17.7063 21.1262 17.2002 20.7332 16.7127 20.2128L16.7113 20.2113C16.4423 19.9226 16.1363 19.8193 15.8683 19.8339C15.6 19.8485 15.3064 19.9848 15.07 20.3L14.0605 21.6493L14.058 21.6527C13.5327 22.3466 12.7933 22.7525 11.995 22.7525C11.1967 22.7525 10.4573 22.3466 9.93202 21.6527L9.92947 21.6493L8.92 20.3C8.6836 19.9848 8.39003 19.8485 8.12172 19.8339C7.85374 19.8193 7.54771 19.9226 7.27871 20.2113L7.27742 20.2127C6.79203 20.731 6.28697 21.1228 5.77494 21.343C5.25572 21.5664 4.68637 21.6279 4.15457 21.3988C3.62795 21.172 3.27503 20.719 3.06116 20.1938C2.84834 19.6711 2.75 19.0276 2.75 18.3V7.04C2.75 5.03644 2.96532 3.4899 3.89481 2.49637ZM4.99019 3.52113C4.50468 4.0401 4.25 5.01356 4.25 7.04V18.3C4.25 18.9074 4.33416 19.3426 4.4504 19.6281C4.56559 19.911 4.6883 19.9955 4.74793 20.0212C4.80238 20.0446 4.9299 20.0736 5.18225 19.9651C5.44169 19.8535 5.78272 19.6142 6.18214 19.1878L6.18129 19.1887L6.73 19.7L6.18214 19.1878C6.73304 18.5971 7.46164 18.2957 8.20328 18.3361C8.94497 18.3765 9.6364 18.7552 10.12 19.4L11.128 20.7473L11.1291 20.7488C11.4135 21.1237 11.7336 21.2525 11.995 21.2525C12.2564 21.2525 12.5765 21.1237 12.8609 20.7488L12.862 20.7473L13.8695 19.4007C14.3531 18.7559 15.045 18.3765 15.7867 18.3361C16.5283 18.2957 17.2569 18.5971 17.8078 19.1877C18.21 19.617 18.5525 19.8576 18.8126 19.9697C19.0655 20.0788 19.1922 20.0493 19.2453 20.0264C19.3038 20.0011 19.4259 19.9172 19.5407 19.6345C19.6564 19.3494 19.74 18.9151 19.74 18.31V7.04L19.74 7.03814C19.745 5.01161 19.4921 4.03886 19.0081 3.52065C18.5406 3.02009 17.6688 2.75 15.78 2.75H8.22C6.3315 2.75 5.45905 3.01996 4.99019 3.52113Z" fill="inherit"/>
  </svg>
  ,
  'Terms'
);

export default TermsIcon;
