import {SvgIconProps} from "./types";


const BlogIcon = (props: SvgIconProps) => {
  const {
    fill,
    type,
    size
  } = props;

  return (
    <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} fill="none" xmlns="http://www.w3.org/2000/svg">
      {type === 'filled' && (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M16.2222 2H7.77778C4.08333 2 2.5 4 2.5 7V17C2.5 20 4.08333 22 7.77778 22H16.2222C19.9167 22 21.5 20 21.5 17V7C21.5 4 19.9167 2 16.2222 2ZM7.77778 12.25H12C12.4328 12.25 12.7917 12.59 12.7917 13C12.7917 13.41 12.4328 13.75 12 13.75H7.77778C7.345 13.75 6.98611 13.41 6.98611 13C6.98611 12.59 7.345 12.25 7.77778 12.25ZM16.2222 17.75H7.77778C7.345 17.75 6.98611 17.41 6.98611 17C6.98611 16.59 7.345 16.25 7.77778 16.25H16.2222C16.655 16.25 17.0139 16.59 17.0139 17C17.0139 17.41 16.655 17.75 16.2222 17.75ZM18.8611 9.25H16.75C15.1456 9.25 13.8472 8.02 13.8472 6.5V4.5C13.8472 4.09 14.2061 3.75 14.6389 3.75C15.0717 3.75 15.4306 4.09 15.4306 4.5V6.5C15.4306 7.19 16.0217 7.75 16.75 7.75H18.8611C19.2939 7.75 19.6528 8.09 19.6528 8.5C19.6528 8.91 19.2939 9.25 18.8611 9.25Z" fill={fill}/>
        </svg>
      )}
      {type === 'outlined' && (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M15.1667 5.02326C15.1667 4.63794 14.8395 4.32558 14.4359 4.32558C14.0323 4.32558 13.7051 4.63794 13.7051 5.02326V6.88372C13.7051 8.29229 14.9092 9.44186 16.3846 9.44186H18.3333C18.7369 9.44186 19.0641 9.1295 19.0641 8.74419C19.0641 8.35887 18.7369 8.04651 18.3333 8.04651H16.3846C15.7164 8.04651 15.1667 7.52166 15.1667 6.88372V5.02326Z" fill={fill}/>
          <path d="M7.37179 12.9302C7.37179 12.5449 7.69897 12.2326 8.10256 12.2326H12C12.4036 12.2326 12.7308 12.5449 12.7308 12.9302C12.7308 13.3155 12.4036 13.6279 12 13.6279H8.10256C7.69897 13.6279 7.37179 13.3155 7.37179 12.9302Z" fill={fill}/>
          <path d="M8.10256 15.9535C7.69897 15.9535 7.37179 16.2658 7.37179 16.6512C7.37179 17.0365 7.69897 17.3488 8.10256 17.3488H15.8974C16.301 17.3488 16.6282 17.0365 16.6282 16.6512C16.6282 16.2658 16.301 15.9535 15.8974 15.9535H8.10256Z" fill={fill}/>
          <path fillRule="evenodd" clipRule="evenodd" d="M8.10256 2C6.25815 2 4.82045 2.50662 3.85232 3.50201C2.89328 4.48804 2.5 5.84654 2.5 7.34884V16.6512C2.5 18.1535 2.89328 19.512 3.85232 20.498C4.82045 21.4934 6.25815 22 8.10256 22H15.8974C17.7418 22 19.1795 21.4934 20.1477 20.498C21.1067 19.512 21.5 18.1535 21.5 16.6512V7.34884C21.5 5.84654 21.1067 4.48804 20.1477 3.50201C19.1795 2.50662 17.7418 2 15.8974 2H8.10256ZM3.96154 7.34884C3.96154 6.06044 4.29902 5.09335 4.92332 4.45148C5.53852 3.81896 6.53672 3.39535 8.10256 3.39535H15.8974C17.4633 3.39535 18.4615 3.81896 19.0767 4.45148C19.701 5.09335 20.0385 6.06044 20.0385 7.34884V16.6512C20.0385 17.9396 19.701 18.9066 19.0767 19.5485C18.4615 20.181 17.4633 20.6047 15.8974 20.6047H8.10256C6.53672 20.6047 5.53852 20.181 4.92332 19.5485C4.29902 18.9066 3.96154 17.9396 3.96154 16.6512V7.34884Z" fill={fill}/>
        </svg>
      )}
    </svg>
  );
};

export default BlogIcon;