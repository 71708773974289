import * as React from 'react';
import {useEffect, useState} from 'react';
import {Container} from '@mui/material';
import axios from 'axios';
import Loading from "../Loading";
import {Currency} from "./types";
import CurrencyHead from "./CurrencyHead";
import {Virtuoso} from 'react-virtuoso';
import CurrencyRow from "./CurrencyRow";
import CurrencyRowPlaceHolder from "./CurrencyRowPlaceHolder";

const transformCurrencies = (data: any): Currency[] => {
  if (!data || !data.data || !data.data.cryptoCurrencyList) {
    return [];
  }
  return data.data.cryptoCurrencyList.map((d: any) => {
    let p = d.split('/');
    return {
      cmcId: p[0],
      cmcRank: p[1],
      name: p[2],
      symbol: p[3],
      slug: p[4],
      percentChange24h: p[5],
      percentChange30d: p[6],
      price: p[7],
      price_toman: p[8],
    };
  })
}

function mergeCurrencies<Type extends Currency>(currentCurrencies: Type[], newCurrencies: Type[]): Type[] {
  return currentCurrencies.concat(newCurrencies.filter((crypto) => {
    let alreadyExists = false;
    for (let i = 0; i < currentCurrencies.length; i++) {
      if (currentCurrencies[i].cmcId === crypto.cmcId) {
        alreadyExists = true
        break;
      }
    }

    return !alreadyExists;
  }))
}
export default function CurrenciesTable() {
  const [loading, setLoading] = useState<boolean>(false)
  const [finished, setFinished] = useState<boolean>(false)
  const [wsUrl, setWsUrl] = useState<string>("")
  const [currencies, setCurrencies] = useState<Currency[]>([])
  const [updates, setUpdates] = useState<any>({});
  const [assets, setAssets] = useState("ALL");
  const [sort, setSort] = useState<'cmcRank' | 'price' | 'percentChange24h'>('cmcRank');
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [nextPage, setNextPage] = useState<number>(1);

  useEffect(() => {
    setLoading(true)
    axios.get(`https://appapi.ramzarz.news/api/cryptocurrency/currencies-table?page=${nextPage}&sort=${sort}&order=${order}`)
      .then(response => response.data)
      .then((data) => {
        setCurrencies(oldCurrencies => mergeCurrencies(oldCurrencies, transformCurrencies(data)))

      }).catch(function (err) {
      if (err.code === "ERR_BAD_REQUEST" && err.response.status === 400 && err.response.data.code === "rest_post_invalid_page_number") {
        setFinished(true);
      }

    }).finally(() => {
      setLoading(false)
    });
  }, [nextPage, sort, order]);

  useEffect(() => {
    setWsUrl(`wss://ws.ramzarz.news/prices?assets=${assets}`);
  }, [assets])

  useEffect(() => {
    setAssets(currencies.map((currency) => currency.slug).join(','));
  }, [currencies])

  useEffect(() => {
    if (!!wsUrl) {
      const ws = new WebSocket(wsUrl);
      ws.onmessage = (event) => {
        if (!loading) {
          setUpdates(JSON.parse(event.data))
        }
      }
      return () => {
        ws.close();
      }
    }
  }, [wsUrl, loading]);

  useEffect(() => {
    setCurrencies(oldCurrencies => oldCurrencies.map((currency) => {
      if(updates[currency.name.toLowerCase()] !== undefined){
        currency.price = updates[currency.name.toLowerCase()];
      }
      return currency;
    }))

  }, [updates]);

  function handleSortChange(newVal: 'cmcRank' | 'price' | 'percentChange24h') {
    setCurrencies([])
    setNextPage(1)
    setSort(newVal);
  }

  function handleOrderChange(newVal: 'asc' | 'desc') {
    setCurrencies([])
    setNextPage(1)
    setOrder(newVal);
  }

  function handleLoadMore(){
    setNextPage(c => c + 1);
  }

  return (
    <Container className="currencies-table custom-font-size" sx={{direction: 'ltr', paddingTop: '0px!important'}} disableGutters maxWidth={false}>
      <CurrencyHead
        sort={sort}
        order={order}
        onSortChange={handleSortChange}
        onOrderChange={handleOrderChange}
      />
      {/*<InfiniteScroll
        dataLength={currencies.length}
        next={handleLoadMore}
        hasMore={true}
        loader={<Loading/>}
        endMessage={
          <p style={{textAlign: 'center'}}>
            <b>اطلاعات بیشتر برای نمایش وجود ندارد</b>
          </p>
        }
        style={{overflow: 'unset'}}
      >
        <Currencies currencies={currencies} />
      </InfiniteScroll>*/}
      <Virtuoso
        useWindowScroll
        data={currencies}
        style={{ height: window.innerHeight-49-59 }}
        endReached={handleLoadMore}
        itemContent={(index, currency) => {
          return (
            <CurrencyRow key={currency.cmcId} currency={currency} />
          );
        }}
        components={{
          Footer: Loading,
          ScrollSeekPlaceholder: CurrencyRowPlaceHolder
        }}
        scrollSeekConfiguration={{
          enter: (velocity) => Math.abs(velocity) > 1000,
          exit: (velocity) => {
            return Math.abs(velocity) < 10;
          }
        }}
      />
    </Container>
  );
}
