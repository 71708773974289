import TableContainer from "@mui/material/TableContainer";
import {Paper, styled} from "@mui/material";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import NumberFormat from "../../components/NumberFormat";
import * as React from "react";
import TableRow from "@mui/material/TableRow";
import {CurrencyInterface} from "./types";
import DollarPrice from "../../components/DollarPrice";
import Percentage from "../../components/Percentage";

const StyledTableRow = styled(TableRow)(({theme}) => ({
  backgroundColor: theme.palette.background.default,
  '& .MuiTableCell-root': {
    borderBottomStyle: 'solid',
    borderBottomWidth: '1px',
    borderBottomColor: theme.palette.divider,
  },
  '& th.MuiTableCell-body': {
    color: theme.palette.text.secondary,
    lineHeight: '22px',
    fontSize: '13px',
    fontWeight: 500,
  }
}));

const Stats = (props: { currency: CurrencyInterface }) => {
  return (
    <TableContainer
      component={Paper}
      elevation={0}
      sx={{
        px: '6px',
        borderWidth: '2px',
        borderColor: (theme) => theme.palette.divider,
        backgroundImage: 'none',
        borderStyle: 'solid',
        borderRadius: '20px!important',
        mb: '30px'
      }}>
      <Table aria-label="simple table" size="small">
        <TableHead>
          <StyledTableRow>
            <TableCell colSpan={2} sx={{fontSize: '0.86rem', py: 1}} align='right'>تغییرات
              قیمت {!!props.currency.nameFa ? props.currency.nameFa : props.currency.name}</TableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>
          <StyledTableRow

          >
            <TableCell component="th" scope="row" align="right">رتبه</TableCell>
            <TableCell align="left">{props.currency.cmcRank}</TableCell>
          </StyledTableRow>
          <StyledTableRow

          >
            <TableCell component="th" scope="row" align="right">ارزش بازار</TableCell>
            <TableCell align="left"><DollarPrice price={props.currency.marketCap} decimals={0} /></TableCell>
          </StyledTableRow>
          <StyledTableRow
            sx={{'&:last-child td, &:last-child th': {border: 0}}}
          >
            <TableCell component="th" scope="row" align="right">حجم معاملات</TableCell>
            <TableCell align="left"><DollarPrice price={props.currency.volume24h} decimals={0} /></TableCell>
          </StyledTableRow>
          <StyledTableRow
            sx={{'&:last-child td, &:last-child th': {border: 0}}}
          >
            <TableCell component="th" scope="row" align="right">تغییرات ۱ ساعته</TableCell>
            <TableCell align="left"><Percentage arrow decimals={1} value={props.currency.percentChange1h}/></TableCell>
          </StyledTableRow>
          <StyledTableRow
            sx={{'&:last-child td, &:last-child th': {border: 0}}}
          >
            <TableCell component="th" scope="row" align="right">تغییرات ۱ روزه</TableCell>
            <TableCell align="left"><Percentage arrow decimals={1} value={props.currency.percentChange24h}/></TableCell>
          </StyledTableRow>
          <StyledTableRow
            sx={{'&:last-child td, &:last-child th': {border: 0}}}
          >
            <TableCell component="th" scope="row" align="right">تغییرات ۱ هفته</TableCell>
            <TableCell align="left"><Percentage arrow  decimals={1}value={props.currency.percentChange7d}/></TableCell>
          </StyledTableRow>
          <StyledTableRow
            sx={{'&:last-child td, &:last-child th': {border: 0}}}
          >
            <TableCell component="th" scope="row" align="right">تغییرات ۳۰ روزه</TableCell>
            <TableCell align="left"><Percentage arrow decimals={1} value={props.currency.percentChange30d}/></TableCell>
          </StyledTableRow>
          <StyledTableRow
            sx={{'&:last-child td, &:last-child th': {border: 0}}}
          >
            <TableCell component="th" scope="row" align="right">تغییرات ۶۰ روزه</TableCell>
            <TableCell align="left"><Percentage arrow decimals={1} value={props.currency.percentChange60d}/></TableCell>
          </StyledTableRow>
          <StyledTableRow
            sx={{'&:last-child td, &:last-child th': {border: 0}}}
          >
            <TableCell component="th" scope="row" align="right">تغییرات ۹۰ روزه</TableCell>
            <TableCell align="left"><Percentage arrow decimals={1} value={props.currency.percentChange90d}/></TableCell>
          </StyledTableRow>
          <StyledTableRow
            sx={{'&:last-child td, &:last-child th': {border: 0}}}
          >
            <TableCell component="th" scope="row" align="right">بالاترین قیمت تاریخ</TableCell>
            <TableCell align="left"><DollarPrice price={props.currency.ath}/></TableCell>
          </StyledTableRow>
          <StyledTableRow
            sx={{'&:last-child td, &:last-child th': {border: 0}}}
          >
            <TableCell component="th" scope="row" align="right">پایین ترین قیمت تاریخ</TableCell>
            <TableCell align="left"><DollarPrice price={props.currency.atl}/></TableCell>
          </StyledTableRow>
          <StyledTableRow
            sx={{'&:last-child td, &:last-child th': {border: 0}}}
          >
            <TableCell component="th" scope="row" align="right">سقف قیمت یک روزه</TableCell>
            <TableCell align="left"><DollarPrice price={props.currency.high24h}/></TableCell>
          </StyledTableRow>
          <StyledTableRow
            sx={{'&:last-child td, &:last-child th': {border: 0}}}
          >
            <TableCell component="th" scope="row" align="right">کف قیمت یک روزه</TableCell>
            <TableCell align="left"><DollarPrice price={props.currency.low24h}/></TableCell>
          </StyledTableRow>
          {props.currency.circulatingSupply && (
            <StyledTableRow
              sx={{'&:last-child td, &:last-child th': {border: 0}}}
            >
              <TableCell component="th" scope="row" align="right">عرضه در گردش</TableCell>
              <TableCell align="left"><NumberFormat decimals={0} price={props.currency.circulatingSupply}/></TableCell>
            </StyledTableRow>
          )}
          {props.currency.maxSupply && (
            <StyledTableRow
              sx={{'&:last-child td, &:last-child th': {border: 0}}}
            >
              <TableCell component="th" scope="row" align="right">عرضه کل </TableCell>
              <TableCell align="left"><NumberFormat decimals={0} price={props.currency.maxSupply}/></TableCell>
            </StyledTableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default Stats;