import {Button} from "@mui/material";
import * as React from "react";
import SortingArrow from "./SortingArrow";

export default function SortableButton(
  props: {
    name: string,
    value: 'cmcRank' | 'price' | 'percentChange24h',
    sort: 'cmcRank' | 'price' | 'percentChange24h',
    order: 'asc' | 'desc',
    onSortChange: (newVal: 'cmcRank' | 'price' | 'percentChange24h') => void,
    onOrderChange: (newVal: 'asc' | 'desc') => void,
  }
) {
  const {
    name,
    value,
    sort,
    order,
    onSortChange,
    onOrderChange
  } = props;

  function handleClick() {
    if (value != sort) {
      onSortChange(value);
    }
    if (order == 'asc') {
      onOrderChange('desc');
    } else {
      onOrderChange('asc');
    }
  }

  return (
    <Button
      variant="text"
      size={'small'}
      color={'inherit'}
      sx={{
        padding: 0,
        '&:hover': {
          backgroundColor: 'inherit'
        },
        justifyContent: 'flex-start',
        minWidth: '0'
      }}
      onClick={handleClick}
      startIcon={<SortingArrow value={value} sort={sort} order={order}/>}
    >{name}</Button>
  );
}