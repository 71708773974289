import {Box, Typography} from "@mui/material";
import EmptySearchResultsSvg from '../../assets/empty-search-results.svg';

export default function EmptySearchResults(){
  return (
    <Box
      sx={{
        textAlign: 'center'
      }}
    >
      <img src={EmptySearchResultsSvg} alt="empty results"/>
      <Typography>هیچ نتیجه ای یافت نشد!</Typography>
    </Box>
  );
}