import {SvgIconProps} from "./types";


const HomeIcon = (props: SvgIconProps) => {
  const {
    fill,
    type,
    size
  } = props;

  return (
    <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} fill="none" xmlns="http://www.w3.org/2000/svg">
      {type === 'filled' && (
        <path fillRule="evenodd" clipRule="evenodd" d="M14.1201 3.15006C12.8802 2.28894 11.2782 2.2829 10.0326 3.13463L1.59357 8.90509C1.24549 9.14311 1.1453 9.63522 1.36981 10.0042C1.59432 10.3733 2.05851 10.4795 2.40659 10.2415L3.24998 9.66478V17.5244C3.24998 19.7201 4.9289 21.5 6.99995 21.5H16.9999C19.0709 21.5 20.7498 19.7201 20.7498 17.5244V9.65562L21.5889 10.2384C21.9354 10.479 22.4003 10.3763 22.6273 10.009C22.8543 9.64167 22.7574 9.14881 22.4109 8.90818L14.1201 3.15006ZM10.4999 16.7293C10.4999 15.851 11.1715 15.1391 11.9999 15.1391C12.8283 15.1391 13.4999 15.851 13.4999 16.7293V19.1146C13.4999 19.5538 13.8357 19.9098 14.2499 19.9098C14.6641 19.9098 14.9999 19.5538 14.9999 19.1146V16.7293C14.9999 14.9728 13.6568 13.5488 11.9999 13.5488C10.3431 13.5488 8.99994 14.9728 8.99994 16.7293V19.1146C8.99994 19.5538 9.33572 19.9098 9.74993 19.9098C10.1641 19.9098 10.4999 19.5538 10.4999 19.1146V16.7293Z"
              fill={fill}
        />
      )}
      {type === 'outlined' && (
        <path fillRule="evenodd" clipRule="evenodd" d="M10.0327 3.13463C11.2783 2.2829 12.8803 2.28894 14.1202 3.15006L22.4111 8.90818C22.7576 9.14881 22.8544 9.64167 22.6275 10.009C22.4005 10.3763 21.9356 10.479 21.5891 10.2384L20.75 9.65562V17.5244C20.75 19.7201 19.0711 21.5 17 21.5H7C4.92893 21.5 3.25 19.7201 3.25 17.5244V9.66478L2.4066 10.2415C2.05851 10.4795 1.59433 10.3733 1.36981 10.0042C1.1453 9.63522 1.24549 9.14311 1.59358 8.90509L10.0327 3.13463ZM13.2982 4.48027L19.25 8.61386V17.5244C19.25 18.8418 18.2426 19.9098 17 19.9098H15V16.7293C15 14.9728 13.6569 13.5488 12 13.5488C10.3431 13.5488 9 14.9728 9 16.7293V19.9098H7C5.75736 19.9098 4.75 18.8418 4.75 17.5244V8.63911L10.8457 4.47102C11.5931 3.95997 12.5543 3.9636 13.2982 4.48027ZM10.5 19.9098H13.5V16.7293C13.5 15.851 12.8284 15.1391 12 15.1391C11.1716 15.1391 10.5 15.851 10.5 16.7293V19.9098Z"
              fill={fill}
        />
      )}
    </svg>
  );
};

export default HomeIcon;