import {Chip} from "@mui/material";
import * as React from "react";
import {useEffect, useState} from "react";

export default function ColorfulChip(props:{value: number, suffix?: string}){
  const {
    value,
    suffix
  } = props;

  const [bgColor, setBgColor] = useState<string>('#E0E0E0');
  const [color, setColor] = useState<string>('#263238');

  useEffect(() => {
    if(value > 0){
      setBgColor('#D9F4DD')
    }else if(value < 0){
      setBgColor('#FBE9E7');
    }else{
      setBgColor('#E0E0E0');
    }
  }, [value])

  useEffect(() => {
    if(value > 0){
      setColor('#02591D')
    }else if(value < 0){
      setColor('#F44336');
    }else{
      setColor('#263238');
    }
  }, [value])

  return (
    <Chip
      sx={{
        backgroundColor: `${bgColor}`,
        color: `${color}`,
        fontSize: '1.1rem',
        direction: 'ltr',
      }}
      size={'medium'}
      label={`${parseFloat(value+'').toFixed(2)} ${suffix ?? ''}`}
    />
  );
}