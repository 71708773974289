import * as React from 'react';
import {useCallback, useEffect, useState} from 'react';
import {Box, Container, Divider, Grid, Typography} from "@mui/material";
import axios from "axios";
import Loading from "../Loading";
import InfiniteScroll from "react-infinite-scroll-component";
import {PostInterface} from "../../types";
import PostsList from "../PostsList";


interface PostsListInterface {
  url: string;
  title: string;
  singlePath: string,
  icon?: any,
}

function Header(props: { title: string, icon?: any }) {
  const {
    title,
    icon
  } = props;
  return (
    <Grid container className={'posts-title'}>
      <Grid item>
        <Typography sx={{my: 1}}>
          {props.icon}
          <Box component='span' sx={{verticalAlign: 'top'}}>{title}</Box>
        </Typography>
      </Grid>
    </Grid>
  );
}

export default function Posts(props: PostsListInterface) {
  const [posts, setPosts] = useState<PostInterface[]>([])
  const [finished, setFinished] = useState<boolean>(false)
  useEffect(() => {
    getPosts();
  }, []);

  const getPosts = useCallback(() => {
    const page = Math.floor(posts.length / 20) + 1;
    axios.get(`${props.url}&page=${page}`)
      .then(response => response.data)
      .then((data) => {
        setPosts(posts.concat(data))
      }).catch(function (err) {
      setFinished(true);
      if (err.code === "ERR_BAD_REQUEST" && err.response.status === 400 && err.response.data.code === "rest_post_invalid_page_number") {
        setFinished(true);
      }
    }).finally(() => {
    });
  }, [posts])
  return (
    <Container disableGutters maxWidth={false} sx={{px: 1}}>
      <Header title={props.title} icon={props.icon}/>
      <Divider/>
      <InfiniteScroll
        dataLength={posts.length}
        next={getPosts}
        hasMore={!finished}
        loader={<Loading/>}
        endMessage={
          <p style={{textAlign: 'center'}}>
            <b>اطلاعات بیشتر برای نمایش وجود ندارد</b>
          </p>
        }
        style={{overflow: 'unset'}}
      >
        <PostsList posts={posts} singlePath={props.singlePath}/>
      </InfiniteScroll>
    </Container>
  );
}
