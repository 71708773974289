import {useTheme} from "@mui/material/styles";
import {Box} from "@mui/material";
import * as React from "react";
import {SvgIconProps} from "../Icons/types";

export function BottomNavigationIcon(props: { active: boolean, icon: React.ComponentType<SvgIconProps> }) {
  const {
    active,
  } = props;
  const theme = useTheme();

  return (
    <Box sx={{
      width: '48px',
      height: '26px',
      textAlign: 'center',
      borderRadius: '13px',
      background: active ? theme.palette.primary.light : 'default',
    }}>
      <props.icon
        size={24}
        fill={active ? theme.palette.primary.dark : theme.palette.text.primary}
        type={ active ? 'filled' : 'outlined'}
      />
    </Box>
  );
}