import {Box, Container, Grid, IconButton, Paper, Typography} from "@mui/material";
import {Link} from "react-router-dom";
import EastIcon from "@mui/icons-material/East";
import * as React from "react";

export default function About() {
  return (
    <Paper className={"about-paper"} elevation={0} sx={{p: 0, m: 0}}>
      <Container maxWidth={false}>
        <Grid container>
          <Grid item xs={12}
                sx={{
                  textAlign: 'center',
                  mb: 2,
                  mt: 1,
                  direction: 'ltr',
                  display: 'flex',
                }}
          >
            <Box
              sx={{
                fontSize: '1.8rem',
                flexGrow: 1,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '4px',
              }}
            >
              <Typography variant='h4'>
                درباره ما
              </Typography>
            </Box>
            <Box>
              <IconButton
                component={Link}
                to={'/profile'}
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                color="inherit"
              >
                <EastIcon fontSize={"large"}/>
              </IconButton>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography
              sx={{
                color: (theme) => theme.palette.primary.dark
              }}
              variant='h5' mt={3} mb={3}>
              درباره رمزارز نیوز
            </Typography>
            <Typography variant='body1'>
              رمزارز نیوز از سال 1397 با جمعی از جوانان متخصص و آشنا به تکنولوژی شروع به کار کرد. حوزه تخصصی مجموعه ما،
              بلاکچین و رمزارز است. اکنون که استخراج رمزارزها به عنوان یک صنعت قانونی در کشورمان تعریف شده است و گستره و
              کاربرد رمزارزها در دنیا روزبه‌روز در حال افزایش است، اهمیت سطح دانش کاربران عزیز در این حوزه بیش از پیش
              احساس می‌شود. رسالت رمزارز نیوز افزایش سطح آشنایی مردم در حوزه تکنولوژی رمزارزها و مشاوره برای استارتاپ‌ها
              در این زمینه است. همچنین ایجاد یک دید منطقی و واقع‌بینانه به پروژه‌های این صنعت محبوب، از اهداف اصلی
              مجموعه رمزارز نیوز بوده و خواهد بود.
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Paper>
  );
}
