import {createSvgIcon} from "@mui/material";

const TelegramIcon = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" fill="inherit" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M21.2218 8.34553C21.8898 6.32553 21.7614 4.4965 20.6317 3.36511C19.501 2.23276 17.6732 2.10498 15.6525 2.77854L7.16177 5.60878C5.68637 6.10231 4.52117 6.69144 3.71004 7.36176C2.90151 8.02993 2.36719 8.84901 2.36719 9.7838C2.36719 10.7188 2.90175 11.5374 3.71065 12.2045C4.52195 12.8735 5.68761 13.4608 7.16289 13.9517L9.32676 14.673L10.0482 16.8372C10.5392 18.3127 11.127 19.4781 11.797 20.2895C12.465 21.0984 13.2845 21.6325 14.2197 21.6325C15.1549 21.6325 15.9744 21.0984 16.6424 20.2895C17.3124 19.4781 17.9003 18.3123 18.3913 16.8368L21.2212 8.34722L21.2218 8.34553ZM16.1269 4.20156C17.9162 3.60511 19.0284 3.88233 19.5702 4.42498C20.1129 4.96851 20.3892 6.08502 19.7976 7.87456L16.9681 16.3633C16.5091 17.7426 15.9945 18.7183 15.4857 19.3344C14.975 19.9529 14.5395 20.1325 14.2197 20.1325C13.8999 20.1325 13.4644 19.9529 12.9536 19.3344C12.4449 18.7183 11.9302 17.7422 11.4712 16.3629L10.7179 14.103L14.2214 10.5896C14.5139 10.2963 14.5132 9.82147 14.2199 9.52899C13.9266 9.23651 13.4518 9.23717 13.1593 9.53047L9.57928 13.1205C9.56555 13.1342 9.55247 13.1484 9.54004 13.1629L7.63648 12.5284C6.25694 12.0694 5.2811 11.5553 4.66498 11.0472C4.04637 10.5371 3.86719 10.1025 3.86719 9.7838C3.86719 9.46483 4.04661 9.02954 4.66558 8.51802C5.28188 8.00871 6.25826 7.49275 7.63761 7.03131L16.1269 4.20156Z"
          fill="inherit"
    />
  </svg>
  ,
  'Telegram'
);

export default TelegramIcon;
