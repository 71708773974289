import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {RouterProvider,} from "react-router-dom";
import router from './router';
import {CssBaseline} from '@mui/material';
import {Workbox} from 'workbox-window';
import {requestPermission} from "./tools/firebase";
import {ThemeProvider} from "./components/ThemeProvider";


if ('serviceWorker' in navigator) {
  const wb = new Workbox('/sw.js');

  wb.register();
}

requestPermission();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
      <ThemeProvider>
        <CssBaseline />
        <RouterProvider router={router} />
      </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
