import * as React from 'react';
import {useEffect, useState} from 'react';
import NewsList from "../../components/NewsList";
import ArticlesList from "../../components/ArticlesList";
import TwinTabs from "../../components/TwinTabs/TwinTabs";
import {useLocation} from "react-router-dom";

const TAB1_LABEL = 'اخبار';
const TAB2_LABEL = 'مقالات';


export default function Blog() {
  const {
    pathname
  } = useLocation();
  const [tab, setTab] = useState<number>(0);

  useEffect(() => {
    if(pathname.startsWith('/articles')){
      setTab(1);
    }else{
      setTab(0)
    }
  }, [pathname]);

  return (
    <TwinTabs
      label1={TAB1_LABEL}
      label2={TAB2_LABEL}
      tab1={<NewsList />}
      tab1Path="/blog"
      tab2={<ArticlesList />}
      tab2Path="/articles"
      defaultTab={tab}
    />
  );
}
