import * as React from 'react';
import CurrenciesTableV1 from "../../components/CurrenciesTableV1";
import {default as CalculatorComponent} from "../../components/Calculator";
import TwinTabs from "../../components/TwinTabs/TwinTabs";
import {useLocation} from "react-router-dom";
import {useEffect, useState} from "react";

const TAB1_LABEL = 'ارزهای دیجیتال';
const TAB2_LABEL = 'ماشین حساب';

export default function Home() {
  const {
    pathname
  } = useLocation();
  const [tab, setTab] = useState<number>(0);

  useEffect(() => {
    if(pathname.startsWith('/calculator')){
      setTab(1);
    }else{
      setTab(0)
    }
  }, [pathname]);

  let versionCode: number;
  // @ts-ignore
  if (window.version) {
    // @ts-ignore
    versionCode = window.version.getVersionCode();
  } else {
    versionCode = 1;
  }

  return (
    <TwinTabs
      label1={TAB1_LABEL}
      label2={TAB2_LABEL}
      tab1={<CurrenciesTableV1 />}
      tab1Path="/"
      tab2={<CalculatorComponent />}
      tab2Path="/calculator"
      defaultTab={tab}
    />
  );
}
