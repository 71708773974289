import * as React from "react";
import {styled} from "@mui/material";
import {grey} from "@mui/material/colors";

const StyledBox = styled('div')(({ theme }) => ({
  backgroundColor: 'background.paper',
}));
const PullerComp = styled('div')(({ theme }) => ({
  width: 30,
  height: 6,
  backgroundColor: theme.palette.mode === 'light' ? grey[300] : grey[800],
  borderRadius: 3,
  position: 'absolute',
  top: 8,
  left: 'calc(50% - 15px)',
}));

const Puller = () => {
  return (
    <StyledBox
      sx={{
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        pb: '16px'
      }}
    >
      <PullerComp />
    </StyledBox>
  );
}

export default Puller;