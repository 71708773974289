import {createTheme, responsiveFontSizes, ThemeOptions} from "@mui/material";



let lightTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#FDB913',
      light: "#FEF6E4",
      dark: '#E57E03'
    },
    info: {
      main: '#278EFF',
    },
    secondary: {
      main: '#fafafa'
    },
    divider: '#F5F6FC'
  },
  typography: {
    fontSize: 11,
    fontFamily: [
      'IRANSansX',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
});
const lightOptions: ThemeOptions = {
  components: {
    MuiButton: {
      styleOverrides: {
        text: {
          color: lightTheme.palette.text.secondary,
        },
        outlined: {
          color: lightTheme.palette.text.primary,
          backgroundColor: lightTheme.palette.primary.light,
          ":hover": {
            backgroundColor: lightTheme.palette.primary.light
          }
        }
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {

        },
        sizeSmall: {
          height: '18px',
          borderRadius: '10px!important',
        }
      }
    }
  }
}
lightTheme = createTheme(responsiveFontSizes(createTheme(lightTheme,  {
  direction: 'rtl',
  components: {
    MuiBottomNavigation: {
      styleOverrides: {
        root: {
          height: '40px',
          fontSize: '0.4875rem'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '16px',
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Iransans';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('Iransans'), local('Iransans-Regular'), url(/fonts/IRANSansWeb.woff2) format('woff2');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
      `,
    },
  },
})), lightOptions);

let darkTheme = createTheme({
  palette: {
    mode: 'dark',
    background: {
      paper: '#252129',
      default: '#252129',
    },
    primary: {
      main: '#FFCA47',
      light: "#453B23",
      dark: '#E57E03'
    },
    info: {
      main: '#278EFF',
    },
    secondary: {
      main: '#fafafa'
    },
    divider: '#403C47'
  },
  typography: {
    fontSize: 11,
    fontFamily: [
      'IRANSansX',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
});
const darkOptions: ThemeOptions = {
  components: {
    MuiButton: {
      styleOverrides: {
        text: {
          color: darkTheme.palette.text.secondary,
        },
        outlined: {
          color: darkTheme.palette.text.primary,
          backgroundColor: darkTheme.palette.primary.light,
          ":hover": {
            backgroundColor: darkTheme.palette.primary.light
          }
        }
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {

        },
        sizeSmall: {
          height: '18px',
          borderRadius: '10px!important',
        }
      }
    }
  }
}
darkTheme = createTheme(responsiveFontSizes(createTheme(darkTheme, {
  direction: 'rtl',
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 0
        }
      }
    },
    MuiBottomNavigation: {
      styleOverrides: {
        root: {
          height: '40px',
          fontSize: '0.4875rem'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '16px',
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: `
        body{
          background-color: '#252129';
        }
        @font-face {
          font-family: 'Iransans';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('Iransans'), local('Iransans-Regular'), url(/fonts/IRANSansWeb.woff2) format('woff2');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
      `,
    },
  },
})), darkOptions);

const Themes = {
  darkTheme,
  lightTheme
}

export  default Themes;
