import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import * as React from "react";

export default function SortingArrow(props: {
  value: 'cmcRank' | 'price' | 'percentChange24h',
  sort: 'cmcRank' | 'price' | 'percentChange24h',
  order: 'asc' | 'desc',
}) {
  const {
    value,
    sort,
    order
  } = props;

  if (sort == value && order == 'desc') {
    return <ArrowDropDownIcon/>;
  } else if (sort == value && order == 'asc') {
    return <ArrowDropUpIcon/>;
  } else {
    return <></>;
  }
}