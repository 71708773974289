import HomeIcon from "../Icons/HomeIcon";
import ExchangesIcon from "../Icons/ExchangesIcon";
import IdeasIcon from "../Icons/IdeasIcon";
import BlogIcon from "../Icons/BlogIcon";

const bottomNavigationItems = [
  {
    value: 0,
    name: 'home',
    icon: HomeIcon,
    link: '/currencies',
    label: 'لیست قیمت',
  },
  {
    value: 1,
    name: 'ideas',
    icon: IdeasIcon,
    link: '/ideas',
    label: 'تحلیل‌ها',
  },
  {
    value: 2,
    name: 'blog',
    icon: BlogIcon,
    link: '/blog',
    label: 'وبلاگ',
  },
  {
    value: 3,
    name: 'exchanges',
    icon: ExchangesIcon,
    link: '/exchanges/bitcoin',
    label: 'صرافی ها',
  },
];

export default bottomNavigationItems;