import {Box, Divider, Grid, Paper, Skeleton, Stack} from "@mui/material";
import * as React from "react";
import {memo} from "react";

const IconAndNameCol = memo(function IconAndNameCol() {
  return (
    <Grid item xs={4} sx={{textAlign: "center"}} justifyContent="center">
      <Stack direction='row'>
        <Box sx={{my: "auto"}} className={"currency-logo"}>
          <Skeleton variant="circular" width={24} height={24} />
        </Box>
        <Stack sx={{textAlign: "left", ml: 1}}>
          <Box>
            <Skeleton width={40} variant="text" sx={{ fontSize: "1rem" }} />
          </Box>
          <Box>
            <Skeleton width={25} variant="text" sx={{ fontSize: "1rem" }} />
          </Box>
        </Stack>
      </Stack>
    </Grid>
  );
});

const PriceCol = memo(function PriceCol() {
  return (
    <Grid item className={"currency-price"} xs={5}
      sx={{textAlign: "center", display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
      <Box className={"dollar-price"} sx={{pl: "10px"}}>
        <Skeleton width={30} variant="text" sx={{ fontSize: "1rem" }} />
      </Box>
      <Box className={"toman-price"} sx={{color: "grey", pl: "10px"}}>
        <Skeleton width={25} variant="text" sx={{ fontSize: "1rem" }} />
      </Box>
    </Grid>
  );
})

const PercentageCol = memo(function PercentageCol(){
  return (
    <Grid item className={"changes-24h"} xs={2} sx={{textAlign: "center", my: "0"}}>
      <Skeleton width={15} variant="text" sx={{ fontSize: "1rem" }} />
    </Grid>
  );
})

const RankCol = memo(function RankCol(){
  return (
    <Grid item xs={1} sx={{textAlign: "center", my: "0"}} className={"rank"}>
      <Skeleton width={10} variant="text" sx={{ fontSize: "1rem" }} />
    </Grid>
  );
})
export default function CurrencyRowPlaceHolder() {
  return (
    <Paper elevation={0} className={"currency-row"}>
      <Grid container sx={{pt: "10px", pb: "8px", mt: "0!important", mb: "0!important", height: "60px!important"}}>
        <RankCol />
        <IconAndNameCol />
        <PriceCol />
        <PercentageCol />
      </Grid>
      <Divider/>
    </Paper>
  );
}
